<template>
  <ion-page>
    <ion-content class="ion-padding-bottom" color="light">
      <ion-grid>
        <ion-row>
          <ion-col size-lg="2" size="auto"> </ion-col>
          <ion-col size-lg="8" class="flex">
            <h1>{{$t("settings.profileSettings")}}</h1>
          </ion-col>
          <ion-col size-lg="2" size="auto"> </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size-lg="2" size="auto"> </ion-col>
          <ion-col size-lg="8" class="flex">
            <h4>{{$t("settings.updateProfile")}}</h4>
          </ion-col>
          <ion-col size-lg="2" size="auto"> </ion-col>
        </ion-row>
        <user-settings
          :submitHandler="onSubmit"
          :loading="loading"
          :errorTxt="errorTxt"
          :success="success"
        />
        <ion-row> </ion-row>
      </ion-grid>
    </ion-content>
    <ion-footer>
      <gbp-footer />
    </ion-footer>
  </ion-page>
</template>

<script lang="ts">
import UserSettings from "@/components/settings/UserSettings.vue";
import GbpFooter from "@/components/Footer.vue";
import {
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonPage,
  IonRow,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useSnackbarPlugin } from "snackbar-vue";
export default defineComponent({
  name: "SettingsPage",
  components: {
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonPage,
    IonRow,
    UserSettings,
    GbpFooter,
  },
  setup() {
    const snack = useSnackbarPlugin();
    const loading = ref(false);
    const success = ref(false);
    const errorTxt = ref("");
    return {
      snack,
      loading,
      success,
      errorTxt,
    };
  },
  mounted() {
    this.$store.dispatch("permit/initFromStorage");
  },
  methods: {
    async onSubmit(values: { password: string; vpassword: string }) {
      if (values.password != values.vpassword) {
        this.errorTxt = this.$t("settings.mustMatch");
        setTimeout(() => {
          this.errorTxt = "";
        }, 2000);
        return;
      }

      this.loading = true;

      const errorMsg = await this.$store.dispatch(
        "admin/updatePassword",
        values.password
      );
      this.snack.show({
        position: "bottom",
        text: errorMsg ? errorMsg : this.$t("settings.passwordSuccess"),
        time: 2500,
        close: true,
      });
      if (!errorMsg) {
        this.success = true;
      }
    },
  },
});
</script>