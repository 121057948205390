<template>
  <div class="w100 accent">
    <ion-grid class="ion-padding ion-hide-md-down">
      <ion-row>
        <ion-col size="4">
          <div class="text--white">{{ $t("footer.copyright") }}</div>
        </ion-col>
        <ion-col class="w100"> </ion-col>
        <ion-col size="auto">
          <div class="text--white ion-text-end nowrap my-pointer">
            <a :href="termsLink" target="_blank">{{ $t("footer.terms") }}</a>
          </div>
        </ion-col>
        <ion-col size="auto">
          <div class="text--white ion-text-end nowrap">
            <a :href="privacyLink" target="_blank">{{
              $t("footer.privacy")
            }}</a>
          </div>
        </ion-col>
        <ion-col size="auto">
          <div class="text--white ion-text-end nowrap">
            <a :href="contactLink" target="_blank">{{
              $t("footer.contactUs")
            }}</a>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid class="ion-padding-vertical ion-hide-md-up" v-if="showbutton">
      <ion-row>
        <ion-col size="auto">
          <div class="text--white ion-text-end nowrap">
            <a :href="contactLink" target="_blank">{{
              $t("footer.copyright")
            }}</a>
          </div>
        </ion-col>
        <ion-col>
          <div class="text--white ion-text-end nowrap">
            <ion-button fill="clear" icon @click="$emit('scrollo')">
              <ion-icon
                :icon="chevronUpCircleOutline"
                button
                color="secondary-contrast"
                size="large"
              ></ion-icon>
            </ion-button>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { IonButton, IonGrid, IonRow, IonCol, IonIcon } from "@ionic/vue";
import { chevronUpCircleOutline } from "ionicons/icons";
import { PRIVACY_POLICY_LINK, TERMS_LINK, CONTACT_LINK } from "@/constants";

export default defineComponent({
  name: "GbpFooter",
  components: {
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
  },
  props: {
    showbutton: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return { chevronUpCircleOutline };
  },
  computed: {
    termsLink() {
      return TERMS_LINK;
    },
    privacyLink() {
      return PRIVACY_POLICY_LINK;
    },
    contactLink() {
      return CONTACT_LINK;
    },
  }
});
</script>
