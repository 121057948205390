<template>
  <ion-page>
    <ion-content class="ion-padding-bottom" color="light">
      <logo-header />
      <ion-grid>
        <ion-row v-if="getcompletedPermit">
          <ion-col size-lg="1" size="auto"> </ion-col>
          <ion-col size-lg="10" size="12" class="">
            <ion-text class="auto ion-text-start ion-padding-start">
              <h1 style="font-size: 48px; margin: 0">
                {{$t("complete.permitNumber")}}{{ getcompletedPermit.permitId }}
              </h1>
            </ion-text>
          </ion-col>
          <ion-col size-lg="1" size="auto"> </ion-col>
        </ion-row>
        <ion-row v-if="getcompletedPermit">
          <ion-col size-lg="1" size="auto"> </ion-col>
          <ion-col size-lg="10" size="12" class="pt-0 pb-0 ion-padding-start">
            <ion-text class="auto ion-text-start ion-padding-start">
              <h2 style="margin: 0">
                <strong>
                  {{$t("complete.expires")}}
                  {{ completedDate }}</strong
                >
              </h2>
            </ion-text>
          </ion-col>
          <ion-col size-md="1" size="auto"> </ion-col>
        </ion-row>
        <ion-row v-if="getcompletedPermit">
          <ion-col size-lg="1" size="auto"> </ion-col>
          <ion-col size-lg="5" size="12" class="pt-0 pb-0 ion-padding-start">
            <div>
              <ion-text class="auto ion-text-start ion-padding-start">
                <h2 style="margin: 0" class="ion-padding-bottom">
                  <strong>{{$t("complete.issuedTo")}}</strong>
                </h2>
                <h2 style="margin: 0">
                  {{
                    getcompletedPermit.firstName +
                    " " +
                    getcompletedPermit.lastName
                  }}
                </h2>
                <h2 style="margin: 12px 0px">
                  {{
                    getcompletedPermit.address +
                    " " +
                    getcompletedPermit.city +
                    " " +
                    getcompletedPermit.zip
                  }}
                </h2>
                <h2 style="margin: 12px 0px">
                  {{ getcompletedPermit.phoneNo }}
                </h2>
                <h2 style="margin: 12px 0px">
                  {{ getcompletedPermit.email }}
                </h2>
              </ion-text>
            </div>
          </ion-col>
          <ion-col
            size-lg="5"
            size="12"
            class="pt-0 pb-0 ion-padding-start"
            v-if="selectedCounty"
          >
            <div>
              <ion-text class="auto ion-text-start ion-padding-start">
                <h2 style="margin: 0" class="ion-padding-bottom">
                  <strong>{{$t("complete.countyContact")}}</strong>
                </h2>
                <h2 style="margin: 0">
                  {{ selectedCounty.name }}
                </h2>
                <h2 style="margin: 12px 0">
                  {{
                    selectedCounty.contactTitle +
                    " " +
                    selectedCounty.contactFirstName +
                    " " +
                    selectedCounty.contactLastName
                  }}
                </h2>
                <h2 style="margin: 12px 0px">
                  {{ selectedCounty.contactAddress }}
                </h2>
                <h2 style="margin: 12px 0px">
                  {{ selectedCounty.contactPhone }}
                </h2>
                <h2 style="margin: 12px 0px">
                  {{ selectedCounty.additionalConditions }}
                </h2>
              </ion-text>
            </div>
          </ion-col>
          <ion-col size-md="1" size="auto"> </ion-col>
        </ion-row>
        <ion-row v-if="getcompletedPermit">
          <ion-col size-lg="1" size="auto"> </ion-col>
          <ion-col size-lg="10" size="12" class="pt-0 pb-0 ion-padding-start">
            <div>
              <ion-text
                class="auto ion-text-start ion-padding-start"
                color="danger"
              >
                <h3>
                  {{$t("complete.notice")}}
                </h3>
              </ion-text>
            </div>
          </ion-col>
          <ion-col size-md="1" size="auto"> </ion-col>
        </ion-row>

        <ion-row v-if="getcompletedPermit">
          <ion-col size-lg="1" size="auto"> </ion-col>
          <ion-col size-lg="10" size="12" class="pt-0 pb-0 ion-padding-start">
            <div>
              <ion-text class="auto ion-text-start ion-padding-start">
                <h1 style="font-size: 48px; margin: 0">{{$t("complete.details")}}</h1>
              </ion-text>
            </div>
          </ion-col>
          <ion-col size-md="1" size="auto"> </ion-col>
        </ion-row>

        <ion-row
          v-if="getcompletedPermit && getcompletedPermit.handPiledVegetation"
        >
          <ion-col size-lg="1" size="auto"> </ion-col>
          <ion-col size-lg="10" size="12" class="pt-0 pb-0 ion-padding-start">
            <div>
              <ion-text class="auto ion-text-start ion-padding-start">
                <h2>FOR: {{ $t("permit.handPiledMin") }}</h2>
              </ion-text>
            </div>
          </ion-col>
          <ion-col size-md="1" size="auto"> </ion-col>
        </ion-row>
        <ion-row
          v-if="
            getcompletedPermit && getcompletedPermit.existingSmallGardenSpot
          "
        >
          <ion-col size-lg="1" size="auto"> </ion-col>
          <ion-col size-lg="10" size="12" class="pt-0 pb-0 ion-padding-start">
            <div>
              <ion-text class="auto ion-text-start ion-padding-start">
                <h2>
                  {{ $t("permit.existingGardenMin") }}
                </h2>
              </ion-text>
            </div>
          </ion-col>
          <ion-col size-md="1" size="auto"> </ion-col>
        </ion-row>

        <ion-row
          v-if="getcompletedPermit && getcompletedPermit.additionalLocationInfo"
        >
          <ion-col size-lg="1" size="auto"> </ion-col>
          <ion-col size-lg="10" size="12" class="pt-0 pb-0 ion-padding-start">
            <div>
              <ion-text class="auto ion-text-start ion-padding-start">
                <h2>
                  {{ getcompletedPermit.additionalLocationInfo }}
                </h2>
              </ion-text>
            </div>
          </ion-col>
          <ion-col size-md="1" size="auto"> </ion-col>
        </ion-row>
        <ion-row v-if="selectedCountyOrdinance" class="ion-padding-top">
          <ion-col size-md="1" size="auto"> </ion-col>
          <ion-col
            size-md="10"
            class="ion-text-start ion-padding-horizontal ion-padding-top"
          >
            <h2 class="ion-padding-bottom">
              <strong>{{$t("complete.ordinance")}}</strong>
            </h2>
            <div
              class="ion-padding"
              style="
                overflow-y: scroll;
                height: 300px;
                background: #ffffff;
                border: 1px solid #d7d8da;
              "
            >
              <p
                v-for="section in selectedCountyOrdinance.sections"
                :key="section"
              >
                {{ section }}
              </p>
            </div>
          </ion-col>
          <ion-col size-md="1" size="auto"> </ion-col>
        </ion-row>
        <ion-row class="ion-padding-top" />
        <ion-row class="ion-padding-top">
          <ion-col size="4"> </ion-col>
          <ion-col size-md="4" class="flex">
            <ion-button
              class="auto"
              color="primary"
              @click="$router.push('/check')"
              >{{ $t("complete.reviewPermitsButton") }}</ion-button
            >
          </ion-col>
          <ion-col size="4"> </ion-col>
        </ion-row>
        <!-- <back-button-link/> -->
        <ion-row class="ion-padding" />
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButton,
  IonContent,
  IonGrid,
  IonCol,
  IonRow,
  IonPage,
  IonText,
} from "@ionic/vue";
import { defineComponent } from "vue";
import LogoHeader from "../components/LogoHeader.vue";
import { dateMixin } from "./../utils/date.format";
// import BackButtonLink from './../components/BackButtonLink.vue';
import { useSnackbarPlugin } from "snackbar-vue";

export default defineComponent({
  name: "CompletePage",
  mixins: [dateMixin],
  components: {
    // BackButtonLink,
    IonButton,
    IonCol,
    IonRow,
    IonGrid,
    IonContent,
    IonPage,
    IonText,
    LogoHeader,
  },
  setup() {
    const snack = useSnackbarPlugin();
    return { snack };
  },
  computed: {
    getcompletedPermit(): any {
      return this.$store.state.permit.completedPermit;
    },
    selectedCounty(): any {
      return this.$store.state.permit.selectedCounty;
    },
    selectedCountyOrdinance(): any {
      return this.$store.state.permit.selectedCountyOrdinance;
    },
    completedDate(): string {
      // this is going to be js date.
      if (this.getcompletedPermit) {
        return this.addDayReadableDate(
          this.getcompletedPermit.submittedAt
            ? this.getcompletedPermit.submittedAt.getTime() / 1000
            : 0
        );
      }
      return ''
    },
  },
  mounted() {
    this.$store.dispatch("permit/initFromStorage");
    setTimeout(() => {
      if (this.getcompletedPermit) {
        this.snack.show({
          position: "bottom",
          text: this.errorTxt ? this.errorTxt : this.$t("complete.permitCreated"),
          time: 2500,
          close: true,
        });
      } else {
        this.$router.push("/check");
      }
    }, 250);
  },
});
</script>