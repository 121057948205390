<template>
  <ion-header>
    <ion-toolbar color="secondary" class="pt-2 pb-2">
      <ion-title>{{ $t("review.updateInformation") }}</ion-title>
      <ion-buttons slot="end">
        <ion-icon
          @click="closeForm"
          class="pr-2 my-pointer"
          button
          :icon="closeOutline"
          size="large"
        />
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <form
      @submit.prevent="
        formError ? {} : onSubmit(values);
        showErrors = true;
      "
      style="position: relative"
    >
      <ion-list
        v-if="loaded"
        class="ion-padding"
        style="background: transparent"
      >
        <ion-item>
          <ion-label position="floating">{{
            $t("permit.firstName")
          }}</ion-label>
          <ion-input
            name="firstName"
            type="text"
            autocomplete
            v-model="firstName"
            @ionChange="firstName = firstName.trim().toUpperCase()"
            enterkeyhint="next"
          ></ion-input>
        </ion-item>
        <input-error
          v-if="firstNameError"
          :show="showErrors"
          :msg="firstNameError"
        />
        <ion-item>
          <ion-label position="floating">{{ $t("permit.lastName") }}</ion-label>
          <ion-input
            name="lastName"
            type="text"
            enterkeyhint="next"
            autocomplete
            v-model="lastName"
            @ionChange="lastName = lastName.trim().toUpperCase()"
          ></ion-input>
        </ion-item>
        <input-error
          v-if="lastNameError"
          :show="showErrors"
          :msg="lastNameError"
        />
        <ion-item>
          <ion-label position="floating">{{ $t("permit.address") }}</ion-label>
          <ion-input
            name="address"
            type="text"
            enterkeyhint="next"
            autocomplete
            v-model="address"
            @ionChange="address = address.trim().toUpperCase()"
          ></ion-input>
        </ion-item>
        <input-error
          v-if="addressError"
          :show="showErrors"
          :msg="addressError"
        />
        <ion-item>
          <ion-label position="floating">{{ $t("permit.city") }}</ion-label>
          <ion-input
            name="city"
            type="text"
            enterkeyhint="next"
            autocomplete
            v-model="city"
            @ionChange="city = city.trim().toUpperCase()"
          ></ion-input>
        </ion-item>
        <input-error v-if="cityError" :show="showErrors" :msg="cityError" />
        <ion-item>
          <ion-label position="floating">{{ $t("permit.zip") }}</ion-label>
          <ion-input
            autocomplete
            name="zip"
            type="number"
            inputmode="numeric"
            @ionChange="formatZip"
            v-model="zip"
            min="10000"
            max="99999"
          ></ion-input>
        </ion-item>
        <input-error v-if="zipError" :show="showErrors" :msg="zipError" />
        <ion-item>
          <ion-label position="floating">{{ $t("permit.phoneNo") }}</ion-label>
          <ion-input
            autocomplete
            name="phoneNo"
            type="tel"
            inputmode="tel"
            v-model="phoneNo"
            @ionChange="formatPhone"
          ></ion-input>
        </ion-item>
        <input-error
          v-if="phoneNoError"
          :show="showErrors"
          :msg="phoneNoError"
        />

        <ion-item>
          <ion-label position="floating">{{ $t("permit.email") }}</ion-label>
          <ion-input
            autocomplete
            name="email"
            type="email"
            inputmode="email"
            v-model="email"
            @ionChange="email.trim().toLowerCase()"
          ></ion-input>
        </ion-item>
        <input-error v-if="emailError" :show="showErrors" :msg="emailError" />

        <ion-item>
          <ion-label position="stacked">{{
            $t("permit.locationInfo")
          }}</ion-label>
          <ion-textarea
            maxlength="500"
            name="additionalLocationInfo"
            type="text"
            v-model="additionalLocationInfo"
            rows="2"
          ></ion-textarea>
        </ion-item>
        <input-error
          v-if="additionalLocationInfoError"
          :show="showErrors"
          :msg="additionalLocationInfoError"
        />
        <div style="height: 60px">
          <!-- spacer for that fixed button -->
        </div>
      </ion-list>
      <div
        class="ion-padding white"
        style="
          position: fixed;
          z-index: 11;
          bottom: 0px;
          width: calc(100% - 12px);
        "
      >
        <ion-button v-if="loaded" type="submit" color="primary" expand="full">{{
          $t("apply.saveButton")
        }}</ion-button>
      </div>
    </form>
  </ion-content>
</template>

<script lang='ts'>
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonIcon,
  IonItem,
  IonInput,
  IonTextarea,
  IonLabel,
  IonToolbar,
  IonTitle,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { closeOutline } from "ionicons/icons";
import {
  addressFake,
  formatPhoneNumber,
  isRequired,
  max500Chars,
  threeCharacters,
  validateEmail,
  validatePhone,
  validateZipCode,
} from "@/utils/form.validators";
import { useField, useForm } from "vee-validate";
import InputError from "../permit/InputError.vue";

export default defineComponent({
  name: "Modal3",
  props: {
    initFormData: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const loaded = ref(false);
    const showErrors = ref(false);

    const { value: firstName, errorMessage: firstNameError } = useField(
      "firstName",
      isRequired
    );
    const { value: lastName, errorMessage: lastNameError } = useField(
      "lastName",
      isRequired
    );
    const { value: address, errorMessage: addressError } = useField(
      "address",
      addressFake
    );
    const { value: city, errorMessage: cityError } = useField(
      "city",
      threeCharacters
    );
    const { value: zip, errorMessage: zipError } = useField(
      "zip",
      validateZipCode
    );
    const { value: phoneNo, errorMessage: phoneNoError } = useField(
      "phoneNo",
      validatePhone
    );
    const { value: email, errorMessage: emailError } = useField(
      "email",
      validateEmail
    );
    const {
      value: additionalLocationInfo,
      errorMessage: additionalLocationInfoError,
    } = useField("additionalLocationInfo", max500Chars);

    const { handleSubmit } = useForm();
    const onSubmit = handleSubmit(() => {
      modalController.dismiss({
        firstName,
        lastName,
        address,
        city,
        zip,
        phoneNo,
        email,
        additionalLocationInfo,
      });
    });

    return {
      firstName,
      lastName,
      address,
      city,
      zip,
      phoneNo,
      email,
      additionalLocationInfo,
      firstNameError,
      lastNameError,
      addressError,
      cityError,
      zipError,
      phoneNoError,
      emailError,
      additionalLocationInfoError,
      loaded,
      closeOutline,
      onSubmit,
      showErrors,
    };
  },
  components: {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonList,
    IonIcon,
    IonItem,
    IonInput,
    IonTextarea,
    IonLabel,
    IonTitle,
    IonToolbar,
    InputError,
  },
  computed: {
    formError(): boolean {
      if (
        this.firstNameError ||
        this.lastNameError ||
        this.addressError ||
        this.cityError ||
        this.zipError ||
        this.phoneNoError ||
        this.emailError ||
        this.additionalLocationInfoError
      ) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    if (this.initFormData) {
      this.firstName = this.initFormData.firstName;
      this.lastName = this.initFormData.lastName;
      this.address = this.initFormData.address;
      this.city = this.initFormData.city;
      this.zip = this.initFormData.zip;
      this.phoneNo = this.initFormData.phoneNo;
      this.email = this.initFormData.email;
      this.additionalLocationInfo = this.initFormData.additionalLocationInfo;
    }
    this.loaded = true;
  },
  methods: {
    closeForm() {
      modalController.dismiss();
    },
    formatPhone(fon: any) {
      this.phoneNo = formatPhoneNumber(fon.detail.value);
    },
    formatZip(zip: any) {
      this.zip = this.zipHip(zip.detail.value).slice(0,5)
    },
    zipHip(zip: string) {
      return ("" + zip).replace(/\D/g, "");
    }
  },
});
</script>