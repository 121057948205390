
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonInfiniteScroll, 
  IonInfiniteScrollContent,
  IonList,
  IonListHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonTitle,
  IonSearchbar,
  IonToolbar,
  modalController
} from "@ionic/vue";
import { defineComponent, ref} from "vue";
import { County } from "@/models/county";
import { closeOutline } from "ionicons/icons";

export default defineComponent({
  name: "Modal",
  setup() {
    const searched = ref(false)
    const search = ref('')
    return { closeOutline, search, searched };
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonInfiniteScroll, 
    IonInfiniteScrollContent,
    IonIcon,
    IonList,
    IonListHeader,
    IonItem,
    IonLabel,
    IonSearchbar,
    IonTitle,
    IonToolbar
  },
  computed: {
    counties(): any {
      return this.$store.state.permit.counties;
    },
  },
  mounted() {
    if (!this.counties || !this.counties.length) {
      this.searchCounties()
    } 
  },
  methods: {
    closeMe(c: County) {
      modalController.dismiss(c)
    },
    searchCounties() {
      this.$store.dispatch("permit/searchCounties", this.search ? this.search.trim() : '')
      setTimeout(()=> {
        this.searched = true;
      }, 500)
    },
    loadData(ev: any) {
      if (this.counties && this.counties.length >= 157) { // TODO what if more
        return // dont search if at end of list
      }
      this.$store.dispatch("permit/getCounties", this.search ? this.search.trim() : '')
      ev.target!.complete();
    }
  }
});
