<template>
  <ion-page>
    <ion-content class="ion-padding-bottom" color="light">
      <logo-header />
      <forgot-form
        :submitHandler="onSubmit"
        :loading="loading"
        class="ion-padding-vertical"
      />
    </ion-content>
    <ion-footer>
      <gbp-footer />
    </ion-footer>
  </ion-page>
</template>

<script lang="ts">
import ForgotForm from "@/components/admin/ForgotForm.vue";
import { IonContent, IonFooter, IonPage } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import LogoHeader from "../components/LogoHeader.vue";
import GbpFooter from "@/components/Footer.vue";
import { useSnackbarPlugin } from "snackbar-vue";

export default defineComponent({
  name: "ForgotPage",
  components: {
    IonContent,
    IonFooter,
    IonPage,
    LogoHeader,
    ForgotForm,
    GbpFooter,
  },
  setup() {
    const snack = useSnackbarPlugin();
    const loading = ref(false);
    return {
      loading,
      snack,
    };
  },
  mounted() {
    this.$store.dispatch("permit/initFromStorage");
  },
  methods: {
    async onSubmit(values: { email: string }) {
      this.loading = true;
      const errorMsg = await this.$store.dispatch(
        "admin/sendResetPasswordEmail",
        values.email
      );
      this.loading = false;
      this.snack.show({
        position: "bottom",
        text:
          errorMsg && errorMsg.length ? errorMsg : this.$t("forgot.emailSent"),
        time: 2500,
        close: true,
      });
      if (!errorMsg || !errorMsg.length) {
        setTimeout(() => {
          this.$router.push("/login");
        }, 3000);
      }
    },
  },
});
</script>