<template>
  <div class="flex ion-padding-horizontal ion-padding-bottom">
    <div style="margin-left: auto">
      <ion-icon
        button
        @click="enabled = !enabled"
        slot="end"
        color="secondary"
        size="large "
        class="my-pointer"
        :icon="enabled ? lockClosedOutline : lockOpenOutline"
      ></ion-icon>
    </div>
  </div>
  <div v-if="ordinance" class="ion-padding white text--accent">
    <i class="o-7"
      >{{ $t("ordinance.lastUpdated") }}:&nbsp;
      {{ readableDate(ordinance.lastUpdated.seconds) }}</i
    >
  </div>
  <ion-list>
    <ion-item lines="inset">
      <div class="flex w100">
        <ion-textarea
          @ionChange="touched ? {} : (touched = true)"
          auto-grow
          :disabled="!enabled"
          v-model="all"
        ></ion-textarea>
      </div>
    </ion-item>
  </ion-list>
  <div class="flex w100 pt-4">
    <div style="margin: auto 0 auto auto">
      <ion-button
        v-show="touched"
        :disabled="!enabled"
        @click="revert"
        color="secondary"
        class="ion-padding-end"
        >{{ $t("ordinance.revertChanges") }}</ion-button
      >
      <ion-button :disabled="!enabled" @click="checkForm">{{
        $t("ordinance.saveChanges")
      }}</ion-button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { IonButton, IonItem, IonIcon, IonList, IonTextarea } from "@ionic/vue";
import { Ordinance } from "@/models/ordinance";
import { lockClosedOutline, lockOpenOutline } from "ionicons/icons";
import { dateMixin } from "@/utils/date.format";
import { useSnackbarPlugin } from "snackbar-vue";

export default defineComponent({
  name: "OrdinanceForm",
  mixins: [dateMixin],
  components: {
    IonButton,
    IonIcon,
    IonItem,
    IonList,
    IonTextarea,
  },
  props: {
    ordinance: {
      type: Ordinance,
      default: null,
    },
  },
  setup(props) {
    const touched = ref(false);
    const enabled = ref(false);
    const snack = useSnackbarPlugin();
    let all = ref("");
    if (props.ordinance && props.ordinance.sections) {
      props.ordinance.sections!.forEach((s) => {
        all = ref(all.value + s + "\n\n ");
      });
    }
    return {
      snack,
      all,
      enabled,
      touched,
      lockClosedOutline,
      lockOpenOutline,
    };
  },
  methods: {
    revert() {
      this.touched = false;
      if (this.ordinance && this.ordinance.sections) {
        this.all = "";
        this.ordinance.sections!.forEach((s) => {
          this.all = this.all + s + "\n\n ";
        });
      }
    },
    async checkForm() {
      this.enabled = false;
      const b = this.all.replace(/(\n\n)+/gi, "\n\n");
      const errorMsg = await this.$store.dispatch("admin/updateOrdinance", {
        id: this.ordinance.id,
        sections: b.split("\n\n"),
      });
      this.snack.show({
        position: "bottom",
        text: errorMsg ? errorMsg : this.$t("ordinance.changesSavedSuccessfully"),
        time: 3000,
        close: true,
      });
      this.enabled = true;
    },
  },
});
</script>


