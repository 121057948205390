import { DateTime } from "luxon";

export const asyncForEach = async (array: any[], callback: Function) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

export const getPermitNumber = (): string => {
    // let d = DateTime.fromJSDate(new Date(), { zone: 'America/New_York' }).toJSDate().getDate().toString()
    // const m = (Number(DateTime.fromJSDate(new Date(), { zone: 'America/New_York' }).toJSDate().getMonth() + 1)).toString()
    // if (Number(d) < 10) {
    //     d = '0'+ d;
    // }
    return (Number(Math.floor(Math.random() * 9000)) + 1000).toString().slice(0, 10);
}