<template>
  <form @submit.prevent="onSubmit(vales)" class="ion-padding-top">
    <ion-grid>
      <ion-row>
        <ion-col size-md="2" size="auto"> </ion-col>
        <ion-col size-md="8" class="ion-text-start ion-padding-end">
          <h3 class="ion-no-margin">
            {{ $t("onboard.billingForm") }}
          </h3>
        </ion-col>
        <ion-col size-md="2" size="auto"> </ion-col>
      </ion-row>
      <ion-row class="ion-padding-top">
        <ion-col size-md="2"> </ion-col>
        <ion-col size-md="8" size="12" style="background: white">
          <h4 class="ion-padding ion-text-start">
            Please provide the following information in order to complete your
            account setup.
          </h4>
          <ion-row>
            <ion-col size="6">
              <ion-item>
                <ion-label position="floating">{{
                  $t("permit.firstName")
                }}</ion-label>
                <ion-input
                  debounce="500"
                  @ionChange="firstName = firstName.trim().toUpperCase()"
                  autocomplete
                  v-model="firstName"
                  name="firstName"
                  type="text"
                  enterkeyhint="next"
                  inputmode="text"
                  maxlength="50"
                ></ion-input>
              </ion-item>
              <input-error
                v-if="firstNameError"
                :show="showErrors"
                :msg="firstNameError"
              />
            </ion-col>
            <ion-col size="6">
              <ion-item>
                <ion-label position="floating">{{
                  $t("permit.lastName")
                }}</ion-label>
                <ion-input
                  debounce="500"
                  @ionChange="lastName = lastName.trim().toUpperCase()"
                  autocomplete
                  v-model="lastName"
                  name="lastName"
                  type="text"
                  enterkeyhint="next"
                  inputmode="text"
                  maxlength="50"
                ></ion-input>
              </ion-item>
              <input-error
                v-if="lastNameError"
                :show="showErrors"
                :msg="lastNameError"
              />
            </ion-col>
          </ion-row>
            <ion-row>
            <ion-col size="12">
              <ion-item>
                <ion-label position="floating">{{
                  $t("permit.address")
                }}</ion-label>
                <ion-input
                  debounce="500"
                  @ionChange="address = address.toUpperCase()"
                  autocomplete
                  v-model="address"
                  name="address"
                  type="text"
                  enterkeyhint="next"
                  inputmode="text"
                  maxlength="500"
                ></ion-input>
              </ion-item>
              <input-error
                v-if="addressError"
                :show="address"
                :msg="addressError"
              />
            </ion-col>
            </ion-row>
          <ion-row>
            <ion-col size="6">
              <ion-item>
                <ion-label position="floating">{{
                  $t("permit.city")
                }}</ion-label>
                <ion-input
                  debounce="500"
                  @ionChange="city = city.trim().toUpperCase()"
                  autocomplete
                  v-model="city"
                  name="city"
                  type="text"
                  enterkeyhint="next"
                  inputmode="text"
                  maxlength="200"
                ></ion-input>
              </ion-item>
              <input-error
                v-if="cityError"
                :show="showErrors"
                :msg="cityError"
              />
            </ion-col>
            <ion-col size="3">
              <ion-item>
                <ion-label position="floating">{{
                  $t("permit.zip")
                }}</ion-label>
                <ion-input
                  autocomplete
                  debounce="500"
                  @ionChange="formatZip($event)"
                  type="number"
                  inputmode="numeric"
                  v-model="zip"
                  name="zip"
                  enterkeyhint="next"
                  min="10000"
                  max="99999"
                ></ion-input>
              </ion-item>
              <input-error v-if="zipError" :show="showErrors" :msg="zipError" />
            </ion-col>
            <ion-col size="3">
              <ion-item>
                <ion-label position="floating">{{
                  $t("permit.state")
                }}</ion-label>
                <ion-input
                  autocomplete
                  debounce="500"
                  type="text"
                  inputmode="text"
                  v-model="state"
                  name="state"
                  enterkeyhint="next"
                ></ion-input>
              </ion-item>
              <input-error
                v-if="stateError"
                :show="showErrors"
                :msg="stateError"
              />
            </ion-col>
          </ion-row>
      <ion-row >
        <ion-col size-md="12" class="ion-text-start ion-padding flex ion-justify-content-between white" >
          <ion-label>
          <a class="my-pointer" style="text-decoration: underline" @click="openModal">{{ $t("onboard.acceptTerms") }}</a>
          </ion-label>
          <ion-checkbox
            aria-label="acceptterms"
            color="primary"
            debounce="500"
            v-model="acceptTerms"
            name="acceptterms"
          ></ion-checkbox>
        </ion-col>
      </ion-row>
          <h4 class="ion-padding ion-text-start">
            To setup automatic billing, you can enter your credit card
            information below. If not, you will be emailed your monthly invoice.
          </h4>
          <ion-list
            class="ion-no-padding ion-padding-top"
            style="background: transparent"
          >
            <ion-item style="background: white">
              <ion-label position="floating">{{
                $t("onboard.nameOnCard")
              }}</ion-label>
              <ion-input
                debounce="500"
                @ionChange="nameOnCard = nameOnCard.trim().toUpperCase()"
                autocomplete
                v-model="nameOnCard"
                name="nameOnCard"
                type="text"
                enterkeyhint="next"
                inputmode="text"
                maxlength="50"
              ></ion-input>
            </ion-item>
            <input-error
              v-if="nameOnCardError"
              :show="nameOnCard"
              :msg="nameOnCardError"
            />
            <ion-item style="background: white">
              <ion-label position="floating">{{
                $t("permit.billingAddress")
              }}</ion-label>
              <ion-input
                debounce="500"
                @ionChange="billingAddress = billingAddress.toUpperCase()"
                autocomplete
                v-model="billingAddress"
                name="address"
                type="text"
                enterkeyhint="next"
                inputmode="text"
                maxlength="500"
              ></ion-input>
            </ion-item>
            <input-error
              v-if="billingAddressError"
              :show="billingAddress"
              :msg="billingAddressError"
            />
          </ion-list>
        </ion-col>
        <ion-col size-md="2"> </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-md="2"> </ion-col>
        <ion-col size-md="8" size="12" style="background: white">
          <stripe-box
            type="submit"
            @paymentMethod="updatePayment"
            :nameOnCard="nameOnCard"
            :address="billingAddress"
            :disable="!formValid"
          />
        </ion-col>
        <ion-col size-md="2"> </ion-col>
      </ion-row>
      <ion-row class="ion-padding"> </ion-row>
    </ion-grid>
  </form>
</template>
<script lang='ts'>
import {
  IonCol,
  IonCheckbox,
  IonGrid,
  IonItem,
  IonInput,
  IonLabel,
  IonList,
  IonRow,
  modalController
} from "@ionic/vue";

import { defineComponent, ref } from "vue";
import InputError from "./../permit/InputError.vue";
import { useForm, useField } from "vee-validate";
import { isRequired, validateEmail, validateZipCode } from "@/utils/form.validators";
import StripeBox from "./StripeBox.vue";
import { useSnackbarPlugin } from "snackbar-vue";
import TermsModal from "./TermsModal.vue";

export default defineComponent({
  name: "OnboardForm",
  components: {
    IonCol,
    IonCheckbox,
    IonGrid,
    IonItem,
    IonInput,
    IonLabel,
    IonList,
    IonRow,
    InputError,
    StripeBox,
  },
  setup() {
    const { handleSubmit } = useForm();
    const onSubmit = handleSubmit(() => {
      //
    });
    const snack = useSnackbarPlugin();

    const { value: firstName, errorMessage: firstNameError } = useField(
      "firstName",
      isRequired
    );
    const { value: lastName, errorMessage: lastNameError } = useField(
      "lastName",
      isRequired
    );
    const { value: address, errorMessage: addressError } = useField(
      "address",
      isRequired
    );
    const { value: city, errorMessage: cityError } = useField(
      "city",
      isRequired
    );
    const { value: state, errorMessage: stateError } = useField(
      "state",
      isRequired
    );
    const { value: zip, errorMessage: zipError } = useField(
      "zip",
      validateZipCode
    );
    const {
      value: acceptTerms,
      errorMessage: acceptTermsError,
    } = useField("acceptTerms");

    const { value: nameOnCard, errorMessage: nameOnCardError } = useField(
      "nameOnCard",
      isRequired
    );
    const { value: billingAddress, errorMessage: billingAddressError } = useField(
      "billingAddress",
      isRequired
    );
    const { value: paymentMethod, errorMessage: paymentMethodError } = useField(
      "paymentMethod",
    //   isRequired
    );
    
    state.value = ref("GEORGIA")

    return {
      firstName,
      lastName,
      address,
      city,
      zip,
      state,
      acceptTerms,
      billingAddress,
      nameOnCard,
      paymentMethod,
      firstNameError,
      lastNameError,
      addressError,
      cityError,
      zipError,
      stateError,
      billingAddressError,
      acceptTermsError,
      nameOnCardError,
      paymentMethodError,
      onSubmit,
      snack,
    };
  },
  computed: {
    formValid(): any {
      return this.firstNameError ||
        this.lastNameError ||
        this.addressError ||
        this.cityError ||
        this.zipError ||
        this.stateError ||
        this.billingAddressError ||
        this.acceptTermsError ||
        this.nameOnCardError ||
        !this.acceptTerms
    }
  },
  methods: {
    async updatePayment(paymentMethod: any) {
      // submit the form if we have everything ,
      // if (!this.formValid)
      // {
      //   this.snack.show({
      //     position: "bottom",
      //     text: "Please check submission.",
      //     time: 2500,
      //     close: true,
      //   });
      //   return;
      // }
      // show snack saying submitting..
      this.snack.show({
        position: "bottom",
        text: "Submitting..",
        time: 2000,
        close: true,
      });
      this.snack.show({
        position: "bottom",
        text: "Please wait.. this takes a few seconds..",
        time: 3000,
        close: true,
      });

      // do something...
      const errorMsg = await this.$store.dispatch("admin/updateCustomer", {
        firstName: this.firstName,
        lastName: this.lastName,
        address: this.address,
        city: this.city,
        zip: this.zip,
        state: this.state,
        acceptTerms: this.acceptTerms,
        billingAddress: this.billingAddress,
        nameOnCard: this.nameOnCard,
        paymentMethod: paymentMethod,
      });

      if (errorMsg) {
        this.snack.show({
          position: "bottom",
          text: errorMsg,
          time: 2500,
          close: true,
        });
        return;
      }

      await this.$store.dispatch("admin/loadCustomer");

      this.snack.show({
        position: "bottom",
        text: "Success. Payment Information Updated.",
        time: 2500,
        close: true,
      });

      setTimeout(() => {
        this.$router.push("/dashboard");
      }, 2500);
    },
    async openModal() {
      const modal = await modalController.create({
        component: TermsModal,
      });
      modal.present();
    },
    formatZip(zip: any) {
      this.zip = this.zipHip(zip.detail.value).slice(0,5)
    },
    zipHip(zip: string) {
      return ("" + zip).replace(/\D/g, "");
    }
  },
});
</script>
