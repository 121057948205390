
import { defineComponent, ref } from "vue";
import {
  IonIcon,
  IonLabel,
  IonPage,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonText
} from "@ionic/vue";
import { locationOutline, cardOutline, personOutline } from "ionicons/icons";
import LogoHeader from "../../components/LogoHeader.vue";


export default defineComponent({
  name: "SettingsTabsPage",
  components: {
    IonIcon,
    IonLabel,
    IonPage,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonText,
    LogoHeader
  },
  setup() {
    const selectedTab = ref('billing')
    const beforeTabChange = () => {
      // do something before tab change
    };
    const afterTabChange = () => {
      // do something after tab change
    };
    return {
      selectedTab,
      locationOutline,
      cardOutline,
      personOutline,
      beforeTabChange,
      afterTabChange,
    };
  },
  mounted() {
    this.$store.dispatch("permit/initFromStorage");
    if (this.$route.path.includes('counties')) {
      this.selectedTab = 'counties'
    } else if (this.$route.path.includes('billing')) {
      this.selectedTab = 'billing'
    }
  },
  computed: {
    adminCounty(): any {
      return this.$store.state.admin.adminCounty;
    },
  },
});
