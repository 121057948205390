
import { FirePermit } from "@/models/permit";
import {
  IonCol,
  IonGrid,
  IonLabel,
  IonRow,
  IonCheckbox,
} from "@ionic/vue";

import { defineComponent, reactive } from "vue";

export default defineComponent({
  name: "OrdinanceForm",
  components: {
    IonCheckbox,
    IonCol,
    IonGrid,
    IonLabel,
    IonRow,
  },
  setup() {
    const formData = reactive(new FirePermit());
    return {
      formData,
    };
  },
computed: {
    selectedCounty(): any {
      return this.$store.state.permit.selectedCounty;
    },
    selectedCountyOrdinance(): any {
      return this.$store.state.permit.selectedCountyOrdinance;
    },
    getSavedFormData(): any {
      return this.$store.state.permit.formData;
    },
  },
  async mounted() {
    setTimeout(async () => {
      if (this.selectedCounty) {
        await this.$store.dispatch("permit/getSelectedCountyOrdinance", this.selectedCounty.id);
      }
      if (this.getSavedFormData) {
        this.formData = Object.assign(this.formData, this.getSavedFormData);
      }
    }, 100);
  },
  methods: {
    saveFormData() {
      this.$store.dispatch("permit/setFormDataNoSave", this.formData);
    },
  },
});
