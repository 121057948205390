
import {
  IonContent,
  IonGrid,
  IonCol,
  IonRow,
  IonList,
  IonItem,
  IonLabel,
  IonPage,
} from "@ionic/vue";
import { defineComponent } from "vue";
import LogoHeader from "../components/LogoHeader.vue";
import { dateMixin } from "./../utils/date.format";
import BackButtonLink from './../components/permit/BackButtonLink.vue';
import { useSnackbarPlugin } from "snackbar-vue";
import BillingForm from "@/components/onboard/Onboard.vue";
import GbpFooter from "@/components/Footer.vue";
import { TERMS_LINK } from "@/constants";

export default defineComponent({
  name: "OnboardPage",
  mixins: [dateMixin],
  components: {
    BackButtonLink,
    BillingForm,
    IonCol,
    IonRow,
    IonGrid,
    IonContent,
    IonPage,
    IonList,
    IonItem,
    IonLabel,
    GbpFooter,
    LogoHeader,
  },
  setup() {
    const snack = useSnackbarPlugin();
    return { snack }
  },
  computed: {
    termsLink(): any {
      return TERMS_LINK;
    },
    myCounties(): any {
      return this.$store.state.admin.myCounties;
    },
  }
});
