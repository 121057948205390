<template>
  <ion-grid v-if="invoice" class="white px-2 mb-4">
    <ion-row>
      <ion-col size="8">
        <h3 class="ion-text-start mt-0 mb-1">
          <strong>#</strong>{{ invoice.number || invoice.dueDate }}
        </h3>
        <ion-text color="success">
          <h4 class="ion-text-start mt-1 mb-1">
            <strong> {{ invoice.paid ? "PAID" : invoice.status }}</strong>
          </h4>
        </ion-text>
      </ion-col>
      <ion-col size="4">
        <h3 class="ion-text-end mt-1 mb-1">
          {{ formatCurrency }}
        </h3>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="8">
        <h5 class="ion-text-start mt-0 mb-1">
          {{ formatPeriodStart }} - {{ formatPeriodEnd }}
        </h5>
      </ion-col>
      <ion-col size="4">
        <ion-text color="primary" v-if="invoice.hostedInvoiceUrl">
          <h4 class="ion-text-end my-pointer my-underline mt-0 mb-2">
            <a :href="invoice.hostedInvoiceUrl" target="_blank">{{$t("billing.viewInvoice")}}</a>
          </h4>
        </ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>
<script lang='ts'>
import { defineComponent } from "vue";
import { Invoice } from "./../../models/invoice";
import { IonGrid, IonRow, IonCol } from "@ionic/vue";
import { dateMixin } from "@/utils/date.format";

export default defineComponent({
  name: "InvoiceBlock",
  mixins: [dateMixin],
  components: {
    IonGrid,
    IonRow,
    IonCol,
  },
  props: {
    invoice: {
      type: Invoice,
      default: null,
    },
  },
  computed: {
    formatCurrency(): any {
      if (this.invoice && this.invoice.total) {
        return `$${(this.invoice.total / 100).toFixed(2)}`;
      }
      return "$0.00";
    },
    formatPeriodEnd(): any {
      if (this.invoice && this.invoice.periodEnd) {
        return this.readableShortDate(this.invoice.periodEnd);
      }
      return "";
    },
    formatPeriodStart(): any {
      if (this.invoice && this.invoice.periodStart) {
        return this.readableShortDate(this.invoice.periodStart);
      }
      return "";
    },
  },
});
</script>
