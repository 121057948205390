<template>
  <div class="gbp-banner">
    <sup>*</sup>NOTICE: Starting April 8th 2024, permits will no longer be
    available over the phone, and will be only available online.
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "NoPhoneBanner",
  components: {},
});
</script>
<style>
.gbp-banner {
  min-height: 48px;
  width: 100%;
  padding: 8px 24px;
  background: #f5a623;
  font-size: 20px;
}
</style>
