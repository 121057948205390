import { Module } from 'vuex'
import { FirebaseService } from './../services/firebase.service';
import { FirestoreDate } from './../models/permit';
import { County } from '@/models/county';

const firebaseService = new FirebaseService();

export interface AdminModuleState {
    initComplete: boolean;
    adminRole: string;
    adminCounty: County;
    adminCountyOrdinance: any;
    adminCountyExcludedDates: any[];
    myUser: any;
    myCounties: any[];
    myCustomer: any;
    myInvoices: any[];
    permits: any[];
    lastDoc: any;
    firstDoc: any;
    moreBefore: boolean;
    moreAfter: boolean;
    pageSize: number; // TODO change this page size.
    weatherAlerts: any; // {features, title, updated}
}
export interface CustomerFormData {
    firstName: string;
    lastName: string;
    address: string;
    city: string;
    zip: string;
    state: string;
    acceptTerms: boolean;
    billingAddress: string;
    nameOnCard: string;
    paymentMethod: object;
    uid?: string;
    email?: string;
}

const getDefaultState = () => {
    return {
        initComplete: false,
        adminRole: 'user',
        adminCounty: null as any,
        adminCountyOrdinance: null as any,
        adminCountyExcludedDates: [] as any,
        myUser: null as any,
        myCounties: [] as any,
        myCustomer: null as any,
        myInvoices: [] as any,
        permits: [] as any,
        lastDoc: null as any,
        firstDoc: null as any,
        moreBefore: false,
        moreAfter: false,
        pageSize: 10,
        weatherAlerts: null as any, // {features, title, updated}
    }
}

export const adminModule: Module<AdminModuleState, {}> = {
    namespaced: true,
    state: getDefaultState(),
    actions: {
        async listenForUser({ state, commit, dispatch }) {
            // listen for user changes.
            firebaseService.onAuthStateChange((firebaseUser: any) => {
                commit('setMyUser', firebaseUser)
                dispatch('loadCustomer', firebaseUser)
                firebaseUser ? dispatch("getMyCounties") : commit('setMyCounties', [])
            });
        },
        async login(context, formData: { email: string; password: string }): Promise<string> {
            try {
                if (formData) {
                    await firebaseService.login(formData.email, formData.password)
                    // user creds are automatically set by authChangeDetect.
                    return ''
                } else {
                    return 'login user'
                }
            } catch (error) {
                return error.toString()
            }
        },
        async logout(): Promise<string> {
            try {
                await firebaseService.logout()
                return ''
            } catch (error) {
                return error.toString()
            }
        },
        async changeActiveCounty({ commit, dispatch }, county) {
            await commit('saveAdminCounty', county)
            // if county changes, load related info.
            dispatch('getAdminCountyOrdinance') // maybe don't need this right away?
            dispatch('getAdminCountyExcludedDates')
        },
        async getAdminCountyOrdinance({ state, commit }) {
            if (state.adminCounty) {
                const snapshot = await firebaseService.getCountyOrdinance(state.adminCounty.id ?? '');
                if (snapshot) {
                    commit('setAdminCountyOrdinance', snapshot)
                }
            }
        },
        async getAdminCountyExcludedDates({ state, commit }) {
            if (state.adminCounty) {
                const snapshot = await firebaseService.getCountyExcludedDates(state.adminCounty.id ?? '');
                if (snapshot) {
                    commit('setAdminCountyExcludedDates', snapshot)
                }
            }
        },
        async getMyCounties({ state, commit, dispatch }) {
            if (state.myUser) {
                // check if old county set.
                let county: any = await localStorage.getItem('gbpAdminCounty')
                if (county) {
                    county = JSON.parse(county)
                }
                const rolesSnapshot = await firebaseService.getAccessRoles(state.myUser.uid);
                const roles = rolesSnapshot.data(); //{'third county' : 'admin', 'other: 'user'}
                const countyIds = [];
                for (const [key, value] of Object.entries(roles)) {
                  countyIds.push(key)
                }
                const countiesSnapshot = await firebaseService.getMyCounties(countyIds);
                if (countiesSnapshot) {
                    commit('setMyCounties', countiesSnapshot)
                    // if no selected county, lets set one up!
                    if (!countiesSnapshot.empty && !state.adminCounty) {
                        await commit('setAdminCounty', {lastCountyId: county ? county.id : null, countiesSnapshot, roles})
                        dispatch('getAdminCountyOrdinance')
                        dispatch('getAdminCountyExcludedDates')
                    }
                }
            }
        },
        async searchPermits({ state, commit }, query) {
            if (state.adminCounty) {
                const q = await firebaseService.formatQuery(state.adminCounty.id ?? '', query)
                try {
                    const snapshot = await firebaseService.getPermits(q, null, state.pageSize)
                    if (snapshot) {
                        commit('setPermits', { snapshot, newSearch: true })
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        },
        async pageNextPermits({ state, commit }, query) {
            if (state.adminCounty) {
                const q = await firebaseService.formatQuery(state.adminCounty.id ?? '', query)
                const snapshot = await firebaseService.getPermits(q, state.lastDoc, state.pageSize)
                if (snapshot) {
                    commit('setPermits', { snapshot, back: false })
                }
            }
        },
        async pageBackPermits({ state, commit }, query) {
            if (state.adminCounty) {
                const q = await firebaseService.formatQuery(state.adminCounty.id ?? '', query)
                const snapshot = await firebaseService.getPermitsPageBack(q, state.firstDoc, state.pageSize)
                if (snapshot) {
                    commit('setPermits', { snapshot, back: true })
                }
            }
        },
        async setPageSize({ commit }, pageSize) {
            commit('setPageSize', pageSize)
        },
        async updateCounty(context, county) {
            // TODO county must have id?
            try {
                await firebaseService.updateCounty(county.id, county);
            } catch (error) {
                return error.toString()
            }
        },

        async updateOrdinance({ state, dispatch }, o: { id: string; sections: string[] }) {
            try {
                if (state.adminCounty) {
                    await firebaseService.updateOrdinance(state.adminCounty.id ?? '', o.id, o.sections);
                    await dispatch('getAdminCountyOrdinance')
                    return ''
                } else {
                    return 'could not update ordinance'
                }
            } catch (error) {
                return error.toString()
            }
        },

        async removeExcludedDate({ state, dispatch }, exDate) {
            try {
                if (state.adminCounty) {
                    await firebaseService.removeExcludedDate(state.adminCounty.id ?? '', exDate.id);
                    dispatch('getAdminCountyExcludedDates')
                    return ''
                }
                return 'could not remove excluded date'
            } catch (error) {
                return error.toString()
            }
        },

        async addExcludedDates({ state, dispatch }, exDates) {
            try {
                if (state.adminCounty) {
                    await firebaseService.addExcludedDates(state.adminCounty.id ?? '', exDates);
                    dispatch('getAdminCountyExcludedDates')
                    return ''
                }
                return 'could not add dates'
            } catch (error) {
                return error.toString()
            }
        },

        async getWeatherAlerts({ commit }): Promise<string> {
            try {
                const alerts: { data: { features: any[]; title: string; updated: FirestoreDate } } = await firebaseService.getWeatherAlerts()
                if (alerts && alerts.data) {
                    commit('setWeatherAlerts', alerts.data)
                } else {
                    commit('setWeatherAlerts', { features: [], updated: new FirestoreDate(new Date().getSeconds()), title: 'Could not load weather alerts.' })
                }
                return ''
            } catch (error) {
                return error.toString()
            }
        },

        async loadCustomer({ commit }, firebaseUser: { uid: string }) {
            try {
                if (firebaseUser) {
                    const snapshot = await firebaseService.loadCustomer(firebaseUser.uid);
                    commit('setCustomer', snapshot)
                    return ''
                } else {
                    commit('setCustomer', null)
                }
                return 'could not load customer'
            } catch (error) {
                return error.toString()
            }
        },

        async updateCustomer({ state, dispatch }, formData: CustomerFormData): Promise<string> {
            try {
                if (state.myUser) {
                    formData.uid = state.myUser.uid;
                    formData.email = state.myUser.email;
                    const result = await firebaseService.updateCustomer(formData);
                    dispatch("loadCustomer", formData)
                    return ''
                } else {
                    throw Error('could not update customer')
                }
            } catch (error) {
                if (error && error.details) {
                    return error.details
                }
                return error.toString()
            }
        },

        async getInvoices({ commit }): Promise<string> {
            try {
                const result: { data: { has_more: boolean; data: any[]; object: string; url: string } } = await firebaseService.getInvoices()
                if (result && result.data) {
                    commit('setInvoices', result.data.data)
                } else {
                    commit('setInvoices', [])
                }
                return ''
            } catch (error) {
                commit('setInvoices', [])
                return error.toString()
            }
        },
        async updatePassword({ state }, newPassword): Promise<string> {
            try {
                if (state.myUser) {
                    await state.myUser.updatePassword(newPassword)
                    return ''
                } else {
                    return 'Could not update password.'
                }
            } catch (error) {
                return error.toString()
            }
        },
        async sendResetPasswordEmail({ state }, email): Promise<string> {
            try {
                if (email) {
                    await firebaseService.sendPasswordResetEmail(email)
                    return ''
                } else {
                    return 'Could not send reset email'
                }
            } catch (error) {
                return error.toString()
            }
        }
    },
    mutations: {
        setMyCounties(state, snapshot) {
            state.myCounties = [];
            snapshot.forEach((doc: any) => {
                const c = doc.data();
                c.id = doc.id
                state.myCounties.push(c);
            });
        },
        setMyUser(state, firebaseUser) {
            state.myUser = firebaseUser
        },
        // coming from firebase snapshot.
        setAdminCounty(state, {lastCountyId, countiesSnapshot, roles}) {
            let matchingCounty = null as County | null;
            if (lastCountyId) {
               // find that one.
               countiesSnapshot.forEach((doc: any) => { // doc.data() is Ordinance type.
                    const c = doc.data();
                    c.id = doc.id // have to slap it on
                    if (c.id == lastCountyId) {
                        matchingCounty = c;
                    }
                });
            }
            if (matchingCounty) {
                state.adminCounty = matchingCounty;
                state.adminRole = roles[matchingCounty.id ?? 0];
            } else {
                const c = countiesSnapshot.docs[countiesSnapshot.size - 1].data();
                c.id = countiesSnapshot.docs[countiesSnapshot.size - 1].id // have to slap it on
                state.adminCounty = c;
                state.adminRole = roles[c.id];
                localStorage.setItem('gbpAdminCounty', JSON.stringify(c))
            }
        },
        // needs to be a pre formatted county.
        saveAdminCounty(state, county) {
            state.adminCounty = county
            localStorage.setItem('gbpAdminCounty', JSON.stringify(county))
        },
        setAdminCountyExcludedDates(state, snapshot) {
            state.adminCountyExcludedDates = [];
            snapshot.forEach((doc: any) => { // doc.data() is ExcludedDate type.
                const c = doc.data();
                c.id = doc.id // have to slap it on
                state.adminCountyExcludedDates.push(c);
            });
        },
        setAdminCountyOrdinance(state, snapshot) {
            snapshot.forEach((doc: any) => { // doc.data() is Ordinance type.
                const c = doc.data();
                c.id = doc.id // have to slap it on
                state.adminCountyOrdinance = c;
            });
        },
        setWeatherAlerts(state, alerts) {
            state.weatherAlerts = alerts;
        },
        setCustomer(state, snapshot) {
            if (snapshot) {

                const c = snapshot.data();
                c.id = snapshot.id; // have to slap it on
                state.myCustomer = c;
            } else {
                // clear customer then.
                state.myCustomer = null;
            }
        },
        setInvoices(state, invoices) {
            state.myInvoices = invoices
        },
        setPermits(state, { snapshot, newSearch, back }) {
            // and now we know if its -> or <- if back is true.
            // permits is a rolling array that contains current page results (ie 10)
            // but remember, requests return 11 results so we can see if one more.
            // depending on direction, need to set the moreBefore moreAfter appropriately.
            // lastDoc and firstDoc reference the start and end for paging requests. index 0 and index 9. not 10!

            if (newSearch) {
                 // we don't want to page, we want to start over.
                state.moreBefore = false; // first page.
                state.moreAfter = snapshot.size > state.pageSize
            } else {
                if (back) {
                    // we went backwards, is there more after this?
                    state.moreBefore = snapshot.size > state.pageSize
                    state.moreAfter = !!state.permits.length
                } else {
                    // we were going forward, and we have more than the 10, so set moreAfter true.
                    state.moreAfter = snapshot.size > state.pageSize
                    // if we hade permits in the last page, well its likely we can page back.
                    state.moreBefore = !!state.permits.length
                }
            }

            // now clear old results.
            state.permits = []
            let index = 0 // snapshot forEach has no index, so have to make your own.
            snapshot.forEach((doc: any) => {
                // TODO if page backwards is it first element and page forward its the last elemet?
                // assuming last element is always the asshole.
                if ((snapshot.size > state.pageSize) && (index + 1) == snapshot.size) { // as in last index and is beyond page limit.
                    return
                }
                if (doc) {
                    const c = doc.data() // FirePermit
                    c.id = doc.id
                    if (state.permits.length == 0) { // first element. for paging.
                        state.firstDoc = doc
                    }

                    state.permits.push(c)

                    if (state.permits.length == state.pageSize) {
                        // last real element. for paging. last actual is only used to set moreAfter and moreBefore
                        state.lastDoc = doc
                    }
                }
                index++
            })
        },
        setPageSize(state, pageSize) {
            state.pageSize = pageSize
            state.lastDoc = null
            state.firstDoc = null
            // clear the paging
        },
    }
}