
import {
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonSpinner,
  IonText,
} from "@ionic/vue";

import { defineComponent, ref } from "vue";
import { checkmarkCircleOutline, closeCircleOutline } from "ionicons/icons";
import { dateMixin } from "../../utils/date.format";

export default defineComponent({
  name: "ArePermitsAvailable",
  mixins: [dateMixin],
  components: {
    IonCol,
    IonGrid,
    IonIcon,
    IonRow,
    IonSpinner,
    IonText,
  },
  setup() {
    const loading = ref(false);
    const init = ref(false);
    return {
      loading,
      init,
      closeCircleOutline,
      checkmarkCircleOutline,
    };
  },
  mounted() {
    setTimeout(() => {
      this.init = true;
    }, 300);
  },
  computed: {
    selectedCounty(): any {
      return this.$store.state.permit.selectedCounty;
    },
    excludedDates(): any[] {
      return this.$store.state.permit.selectedCountyExcludedDates;
    },
    areAvailable(): boolean {
      return this.$store.getters["permit/areAvailable"];
    },
    reason(): string {
      return this.$store.getters["permit/reason"];
    },
    today(): any {
      return this.todaysDate();
    },
  },
});
