<template>
  <ion-header>
    <ion-toolbar
      color="secondary" class="pt-2 pb-2"
    >
      <ion-title>{{$t("apply.selectCounty")}}</ion-title>
      <ion-buttons slot="end">
        <ion-icon
          @click="closeMe(null)"
          class="pr-2 my-pointer"
          button
          :icon="closeOutline"
          size="large"
        />
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding-bottom ion-padding-end">
    <ion-list class="ion-padding-horizontal">
      <ion-list-header>
        <ion-searchbar
          v-model="search"
          :placeholder="$t('apply.searchCounty')"
          show-cancel-button="never"
          color="light"
          debounce="300"
          @ionChange="searchCounties"
        ></ion-searchbar>
      </ion-list-header>
      <div class="ion-padding"></div>
      <ion-item v-for="c in counties" :key="c.name" @click="closeMe(c)" class="my-pointer" button>
        <ion-label>
          <h2>{{c.name}}</h2>
          <p>{{c.additionalConditions}}</p>
        </ion-label>
      </ion-item>
      <ion-item class="ion-padding-horizontal" v-if="searched && counties.length == 0" disabled lines="none">
        <ion-label>
          <h2>{{$t("apply.searchEmpty")}}</h2>
        </ion-label>
      </ion-item>
    </ion-list>
      <ion-infinite-scroll
        @ionInfinite="loadData($event)" 
        threshold="100px" 
        id="infinite-scroll"
      >
        <ion-infinite-scroll-content
          loading-spinner="crescent">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
  </ion-content>
</template>

<script lang='ts'>
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonInfiniteScroll, 
  IonInfiniteScrollContent,
  IonList,
  IonListHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonTitle,
  IonSearchbar,
  IonToolbar,
  modalController
} from "@ionic/vue";
import { defineComponent, ref} from "vue";
import { County } from "@/models/county";
import { closeOutline } from "ionicons/icons";

export default defineComponent({
  name: "Modal",
  setup() {
    const searched = ref(false)
    const search = ref('')
    return { closeOutline, search, searched };
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonInfiniteScroll, 
    IonInfiniteScrollContent,
    IonIcon,
    IonList,
    IonListHeader,
    IonItem,
    IonLabel,
    IonSearchbar,
    IonTitle,
    IonToolbar
  },
  computed: {
    counties(): any {
      return this.$store.state.permit.counties;
    },
  },
  mounted() {
    if (!this.counties || !this.counties.length) {
      this.searchCounties()
    } 
  },
  methods: {
    closeMe(c: County) {
      modalController.dismiss(c)
    },
    searchCounties() {
      this.$store.dispatch("permit/searchCounties", this.search ? this.search.trim() : '')
      setTimeout(()=> {
        this.searched = true;
      }, 500)
    },
    loadData(ev: any) {
      if (this.counties && this.counties.length >= 157) { // TODO what if more
        return // dont search if at end of list
      }
      this.$store.dispatch("permit/getCounties", this.search ? this.search.trim() : '')
      ev.target!.complete();
    }
  }
});
</script>