
import {
  IonButton,
  IonContent,
  IonPage,
  IonGrid,
  IonIcon,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { chevronForwardOutline } from "ionicons/icons";
import { County } from "@/models/county";
import GbpFooter from "@/components/Footer.vue";

export default defineComponent({
  name: "CountiesPage",
  components: {
    IonButton,
    IonContent,
    IonCol,
    IonPage,
    IonGrid,
    IonIcon,
    IonRow,
    IonList,
    IonItem,
    IonLabel,
    GbpFooter
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { chevronForwardOutline };
  },
  computed: {
    myCounties(): any {
      return this.$store.state.admin.myCounties;
    },
  },
  mounted() {
    if (!this.myCounties || !this.myCounties.length)
    this.$store.dispatch("admin/getMyCounties");
  },
  methods: {
    async changeActiveCounty(county: County) {
      await this.$store.dispatch("admin/changeActiveCounty", county);
      this.$router.push("/admin");
    },
  },
});
