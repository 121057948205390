import domtoimage from 'dom-to-image';

export class PrintService {
    public static print(nodeName: string) {
        const node = document.getElementById(nodeName);
        domtoimage.toPng(node)
        .then(function (dataUrl: any) {
            const popup = window.open();
              popup!.document.write('<img src=' + dataUrl + '>');
              popup!.document.close();
              popup!.focus();
              popup!.print();
              popup!.close();
        })
        .catch(function (error: any) {
            console.error('could not print document.', error);
        });
      }
}
