<template>
  <ion-page>
    <ion-content class="ion-padding-top" color="light">
      <ion-grid>
        <ion-row
          class="
            ion-justify-items-center ion-padding-horizontal ion-padding-top
          "
        >
          <ion-col size-lg="2"> </ion-col>
          <ion-col size-lg="8" size="12">
            <h1 class="ion-no-margin ion-text-start">
              {{ $t("admin.calendarPageTitle") }}
            </h1>
          </ion-col>
          <ion-col size-lg="2"> </ion-col>
        </ion-row>
        <ion-row />
        <ion-row
          class="
            ion-justify-items-center ion-padding-top ion-padding-horizontal
          "
        >
          <ion-col size-lg="2"> </ion-col>
          <ion-col size-lg="8" size="12">
            <h4 class="ion-no-margin ion-text-start">
              {{
                $t("admin.calendarDescription") + $t("admin.theCalendarShows")
              }}
            </h4>
          </ion-col>
          <ion-col size-lg="2"> </ion-col>
        </ion-row>
        <ion-row class="ion-justify-items-center ion-padding-topopenPop">
          <ion-col size-lg="2" class="ion-hide-sm-down"> </ion-col>
          <ion-col size-lg="3" size="12">
            <h5 class="ion-text-start ion-padding-horizontal ion-padding-top">
              {{ $t("admin.addDaysDescription") }}
            </h5>
          </ion-col>
          <ion-col size-lg="5" size="12">
            <ion-grid class="ion-no-padding">
              <ion-row class="ion-padding">
                <ion-col>
                  <div>
                    <DatePicker
                      color="orange"
                      v-model="range"
                      mode="date"
                      :masks="masks"
                      timezone="America/New_York"
                      is-range
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <ion-row>
                          <ion-col
                            size-lg="6"
                            size="auto"
                            class="ion-padding-end"
                          >
                            <div class="pb-1">{{ $t("admin.startDate") }}</div>
                            <ion-item>
                              <input
                                style="border: none; background: white"
                                :value="inputValue.start"
                                v-on="inputEvents.start"
                              />
                            </ion-item>
                          </ion-col>
                          <ion-col
                            size-lg="6"
                            size="auto"
                            class="ion-padding-start"
                          >
                            <div class="pb-1">{{ $t("admin.endDate") }}</div>
                            <ion-item>
                              <input
                                style="border: none; background: white"
                                :value="inputValue.end"
                                v-on="inputEvents.end"
                              />
                            </ion-item>
                          </ion-col>
                        </ion-row>
                      </template>
                    </DatePicker>
                  </div>
                  <div class="ion-padding-top">
                    <div class="ion-no-margin pb-1">
                      {{ $t("admin.reasonForBan") }}
                    </div>
                    <ion-item style="ion-padding-top">
                      <ion-input autocomplete v-model="reason"></ion-input>
                    </ion-item>
                  </div>
                </ion-col>
                <!-- <ion-col size-sm="3" class="flex">
                  <div class="auto ion-padding" style="margin-right: 0px">
                    <ion-button @click="addDays"> SAVE DATES </ion-button>
                  </div>
                </ion-col> -->
              </ion-row>
              <ion-row class="ion-justify-content-end">
                <ion-col size="auto">
                  <div class="ion-padding">
                    <ion-button @click="addDays">
                      {{ $t("admin.saveDatesButton") }}
                    </ion-button>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-col>
          <ion-col size-lg="2" class="ion-hide-sm-down"> </ion-col>
        </ion-row>
        <ion-row class="ion-justify-items-center">
          <ion-col size-lg="2"> </ion-col>
          <ion-col size-lg="8" size="12" class="flex">
            <burn-calendar class="auto" @remove="removeDay" />
          </ion-col>
          <ion-col size-lg="2"> </ion-col>
        </ion-row>
        <ion-row class="ion-padding"> </ion-row>
      </ion-grid>
      <gbp-footer />
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButton,
  IonContent,
  IonGrid,
  IonCol,
  IonInput,
  IonItem,
  IonRow,
  IonPage,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { DatePicker } from "v-calendar";
import { chevronDownOutline } from "ionicons/icons";
import BurnCalendar from "../../components/admin/BurnCalendar.vue";
import { dateMixin } from "@/utils/date.format";
import { useSnackbarPlugin } from "snackbar-vue";
import { ExcludedDate } from "@/models/excluded";
import GbpFooter from "@/components/Footer.vue";

export default defineComponent({
  name: "CountyCalendar",
  mixins: [dateMixin],
  components: {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonInput,
    IonItem,
    DatePicker,
    IonRow,
    IonPage,
    BurnCalendar,
    GbpFooter,
  },
  setup() {
    const range = { start: new Date(), end: new Date() };
    const masks = {
      input: "YYYY-MM-DD",
    };
    const reason = ref("no burning allowed");
    const snack = useSnackbarPlugin();
    const days: any[] = [];
    return {
      chevronDownOutline,
      snack,
      reason,
      masks,
      days,
      range,
    };
  },

  methods: {
    async removeDay(day: ExcludedDate) {
      const errorTxt = await this.$store.dispatch("admin/removeExcludedDate", day);
      this.snack.show({
        position: "bottom",
        text: errorTxt ? errorTxt : "removed.",
        time: 3000,
        close: true,
      });
    },
    async addDays() {
      const newDates = this.generateExcludedDays(this.range);
      if (newDates.length == 0) {
        this.snack.show({
          position: "bottom",
          text: this.$t("admin.couldNotAddDays"), // TODO why those dates more info pplease.
          time: 3000,
          close: true,
        });
        return;
      }
      const errorTxt = await this.$store.dispatch("admin/addExcludedDates", newDates);
      this.snack.show({
        position: "bottom",
        text: errorTxt ? errorTxt : "Dates were added successfully",
        time: 3000,
        close: true,
      });
    },
    generateExcludedDays(data: { start: Date; end: Date }) {
      // great we have start and end.
      // now we have to format into array of days.
      let getDaysArray = [];
      const d = new Date(data.start);
      for (; d <= data.end; d.setDate(d.getDate() + 1)) {
        getDaysArray.push(new Date(d));
      }
      // lets weed out the repeats.
      getDaysArray = getDaysArray.filter((day) => {
        let valid = true;
        this.excludedDates.forEach((existingDate) => {
          if (
            this.readableShortDate(existingDate.date.seconds) ==
            this.readableShortDate(day.getTime() / 1000)
          ) {
            valid = false;
          }
        });
        return valid;
      });

      // now we need to format these dates into the things. 12 noon local time or what!
      const output: any[] = [];
      getDaysArray.forEach((day) => {
        const b = new Date(day);
        b.setHours(12); // TODO is this insane?
        b.setMilliseconds(0);
        b.setMinutes(0);
        b.setSeconds(0);
        output.push({
          reason: this.reason, // shared
          date: b,
          u: this.myUser.uid ?? ''
        });
      });
      return output;
    },
  },
  computed: {
    adminCounty(): any {
      return this.$store.state.admin.adminCounty;
    },
    myUser(): any {
      return this.$store.state.admin.myUser;
    },
    excludedDates(): any[] {
      return this.$store.state.admin.adminCountyExcludedDates;
    },
  },
});
</script>