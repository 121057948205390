
import { IonContent, IonPage } from "@ionic/vue";
import { defineComponent } from "vue";
import CountyInfoForm from "../../components/admin/CountyInfoForm.vue";
import GbpFooter from "@/components/Footer.vue";

export default defineComponent({
  name: "CountyDetails",
  components: {
    IonContent,
    IonPage,
    CountyInfoForm,
    GbpFooter,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    adminCounty(): any {
      return this.$store.state.admin.adminCounty;
    },
  },
});
