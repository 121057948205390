
import {
  IonButton,
  IonContent,
  IonGrid,
  IonCol,
  IonInput,
  IonItem,
  IonRow,
  IonPage,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { DatePicker } from "v-calendar";
import { chevronDownOutline } from "ionicons/icons";
import BurnCalendar from "../../components/admin/BurnCalendar.vue";
import { dateMixin } from "@/utils/date.format";
import { useSnackbarPlugin } from "snackbar-vue";
import { ExcludedDate } from "@/models/excluded";
import GbpFooter from "@/components/Footer.vue";

export default defineComponent({
  name: "CountyCalendar",
  mixins: [dateMixin],
  components: {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonInput,
    IonItem,
    DatePicker,
    IonRow,
    IonPage,
    BurnCalendar,
    GbpFooter,
  },
  setup() {
    const range = { start: new Date(), end: new Date() };
    const masks = {
      input: "YYYY-MM-DD",
    };
    const reason = ref("no burning allowed");
    const snack = useSnackbarPlugin();
    const days: any[] = [];
    return {
      chevronDownOutline,
      snack,
      reason,
      masks,
      days,
      range,
    };
  },

  methods: {
    async removeDay(day: ExcludedDate) {
      const errorTxt = await this.$store.dispatch("admin/removeExcludedDate", day);
      this.snack.show({
        position: "bottom",
        text: errorTxt ? errorTxt : "removed.",
        time: 3000,
        close: true,
      });
    },
    async addDays() {
      const newDates = this.generateExcludedDays(this.range);
      if (newDates.length == 0) {
        this.snack.show({
          position: "bottom",
          text: this.$t("admin.couldNotAddDays"), // TODO why those dates more info pplease.
          time: 3000,
          close: true,
        });
        return;
      }
      const errorTxt = await this.$store.dispatch("admin/addExcludedDates", newDates);
      this.snack.show({
        position: "bottom",
        text: errorTxt ? errorTxt : "Dates were added successfully",
        time: 3000,
        close: true,
      });
    },
    generateExcludedDays(data: { start: Date; end: Date }) {
      // great we have start and end.
      // now we have to format into array of days.
      let getDaysArray = [];
      const d = new Date(data.start);
      for (; d <= data.end; d.setDate(d.getDate() + 1)) {
        getDaysArray.push(new Date(d));
      }
      // lets weed out the repeats.
      getDaysArray = getDaysArray.filter((day) => {
        let valid = true;
        this.excludedDates.forEach((existingDate) => {
          if (
            this.readableShortDate(existingDate.date.seconds) ==
            this.readableShortDate(day.getTime() / 1000)
          ) {
            valid = false;
          }
        });
        return valid;
      });

      // now we need to format these dates into the things. 12 noon local time or what!
      const output: any[] = [];
      getDaysArray.forEach((day) => {
        const b = new Date(day);
        b.setHours(12); // TODO is this insane?
        b.setMilliseconds(0);
        b.setMinutes(0);
        b.setSeconds(0);
        output.push({
          reason: this.reason, // shared
          date: b,
          u: this.myUser.uid ?? ''
        });
      });
      return output;
    },
  },
  computed: {
    adminCounty(): any {
      return this.$store.state.admin.adminCounty;
    },
    myUser(): any {
      return this.$store.state.admin.myUser;
    },
    excludedDates(): any[] {
      return this.$store.state.admin.adminCountyExcludedDates;
    },
  },
});
