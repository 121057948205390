<template>
  <ion-row>
    <ion-col size-lg="6" size="12">
      <DatePicker
        color="orange"
        v-model="range"
        mode="date"
        :masks="masks"
        timezone="America/New_York"
        is-range
        :style="{
          opacity: active || email || phoneNo || permitId ? '0.5' : '1',
        }"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <ion-grid style="padding: 0px">
            <ion-row>
              <ion-col size-lg="6" size="12">
                <div class="px-2">
                  <label for="period-start">
                    {{ $t("admin.startDate") }}
                  </label>
                </div>
                <div class="pa-2">
                  <ion-item class="shad">
                    <input
                      id="period-start"
                      style="border: none; background: white"
                      :value="inputValue.start"
                      v-on="inputEvents.start"
                    />
                  </ion-item>
                </div>
              </ion-col>
              <ion-col size-lg="6" size="12">
                <div class="pb-2 px-2">
                  <label for="period-end">
                    {{ $t("admin.endDate") }}
                  </label>
                </div>
                <div class="px-2">
                  <ion-item class="shad">
                    <input
                      id="period-end"
                      class="pa-2"
                      style="border: none; background: white"
                      :value="inputValue.end"
                      v-on="inputEvents.end"
                    />
                  </ion-item>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </template>
      </DatePicker>
    </ion-col>
    <ion-col size-lg="3" size-sm="6" size="12" style="margin: auto 0px">
      <div class="nowrap px-3">{{ $t("permit.phoneNo") }}</div>
      <ion-searchbar
        v-model="phoneNo"
        @ionChange="formatPhone"
        placeholder="(xxx) xxx-xxxx"
        class="pl-3 no-icon limit-width"
      />
    </ion-col>
    <ion-col size-lg="3" size-sm="6" size="12" style="margin: auto 0px">
      <div class="nowrap px-3">{{ $t("permit.email") }}</div>
      <ion-searchbar
        v-model="email"
        :placeholder="$t('permit.searchEmail')"
        class="px-3 no-icon limit-width"
      />
    </ion-col>
    <ion-col size-lg="3" size-sm="6" size="12" style="margin: auto 0px">
      <div class="nowrap px-3">{{ $t("permit.permitNumber") }}</div>
      <ion-searchbar
        v-model="permitId"
        :placeholder="$t('permit.searchEmail')"
        class="px-3 no-icon limit-width"
      />
    </ion-col>
    <ion-col size-lg="auto" size="auto" style="margin: auto 0px">
      <div>
        <div class="nowrap" style="padding: 0px 16px">
          {{ $t("dashboard.filterActive") }}
        </div>
        <div class="w100 flex" style="padding: 16px 24px">
          <ion-checkbox
            aria-label="activepermits"
            style="margin-left: auto; transform: scale(1.25)"
            color="primary"
            debounce="500"
            v-model="active"
            name="activepermits"
          ></ion-checkbox>
        </div>
      </div>
    </ion-col>
    <ion-col class="ion-align-self-stretch flex" style="margin: auto 0px">
      <div
        class="ion-padding-top ion-padding-end"
        style="margin: 0px 0px 0px auto"
      >
        <ion-button
          :disabled="!validEmail || !validPhone"
          style="margin: 0px 0px 0px auto"
          @click="searchPermits()"
        >
          {{ $t("dashboard.search") }}
        </ion-button>
      </div>
    </ion-col>
  </ion-row>
  <div class="ion-padding-top flex">
    <ion-button
      fill="clear"
      class="text--primary-dark"
      style="margin: 0px 0px 0px auto"
      @click="download()"
    >
      {{ $t("dashboard.download") }}
      <ion-icon style="padding-left: 8px" :icon="downloadOutline"
    /></ion-button>
  </div>
  <data-table-lite
    class="gbp-table"
    :is-loading="table.isLoading"
    :is-re-search="table.isReSearch"
    :columns="table.columns"
    :rows="getPermits"
    :total="getPermits.length"
    :sortable="table.sortable"
    :pageSize="pageSize"
    :canpageup="canPageUp"
    :canpagedown="canPageDown"
    @do-search="doSearch"
    @is-finished="tableLoadingFinish"
    @pagesize="setPageSize"
    @pagedown="pageBackPermits"
    @pageup="pageNextPermits"
  >
  </data-table-lite>
</template>
<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import DataTableLite from "./DataTableLite.vue";
import { tableColumns } from "../../utils/table.columns";
import { dateMixin } from "../../utils/date.format";
import { DatePicker } from "v-calendar";
import {
  IonGrid,
  IonButton,
  IonCol,
  IonCheckbox,
  IonItem,
  IonIcon,
  IonRow,
  IonSearchbar,
  modalController,
} from "@ionic/vue";
import { downloadOutline } from "ionicons/icons";
import PermitModal from "./PermitModal.vue";
import { downloadCSV } from "../../utils/json2csv";
import { FirePermit } from "@/models/permit";
import {
  formatPhoneNumber,
  validateEmail,
  validatePhone,
} from "@/utils/form.validators";

export default defineComponent({
  name: "DateTable",
  mixins: [dateMixin],
  components: {
    IonButton,
    IonCheckbox,
    IonCol,
    IonGrid,
    IonIcon,
    IonItem,
    IonRow,
    IonSearchbar,
    DataTableLite,
    DatePicker,
  },
  computed: {
    adminCounty(): any {
      return this.$store.state.admin.adminCounty;
    },
    getPermits(): any {
      return this.$store.state.admin.permits;
    },
    pageSize(): number {
      return this.$store.state.admin.pageSize;
    },
    canPageUp(): boolean {
      return this.$store.state.admin.moreAfter;
    },
    canPageDown(): boolean {
      return this.$store.state.admin.moreBefore;
    },
    validEmail(): boolean {
      if (!this.email) {
        return true;
      }
      return typeof validateEmail(this.email) == "boolean";
    },
    validPhone(): boolean {
      if (!this.phoneNo) {
        return true;
      }
      return typeof validatePhone(this.phoneNo) == "boolean";
    },
  },
  setup() {
    const d = new Date();
    const s = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 7); // store takes care of start of day, end of day.
    const e = new Date(d.getFullYear(), d.getMonth(), d.getDate());
    const range = {
      start: s,
      end: e,
    };
    const masks = {
      input: "YYYY-MM-DD",
    };
    const email = ref("");
    const permitId = ref("");
    const address = ref("");
    const phoneNo = ref("");
    const type = ref("");
    const active = ref(false);
    const table = reactive({
      isLoading: false,
      isReSearch: false,
      columns: tableColumns,
      sortable: {
        order: "submittedAt",
        sort: "desc",
      },
    });
    return {
      table,
      masks,
      range,
      email,
      permitId,
      address,
      phoneNo,
      type,
      active,
      downloadOutline,
    };
  },
  mounted() {
    setTimeout(async () => {
      if (this.adminCounty) {
        this.searchPermits();
      }
    }, 3000);
  },
  methods: {
    async setPageSize(pageSize: number) {
      if (pageSize) {
        await this.$store.dispatch("admin/setPageSize", pageSize);
        this.searchPermits();
      }
    },
    pageBackPermits() {
      this.$store.dispatch("admin/pageBackPermits", {
        active: this.active,
        address: this.address,
        permitId: this.permitId,
        email: this.email,
        end: this.range.end,
        start: this.range.start,
        phoneNo: this.phoneNo,
        type: this.type,
      });
    },
    pageNextPermits() {
      this.$store.dispatch("admin/pageNextPermits", {
        active: this.active,
        address: this.address,
        permitId: this.permitId,
        email: this.email,
        end: this.range.end,
        start: this.range.start,
        phoneNo: this.phoneNo,
        type: this.type,
      });
    },
    searchPermits() {
      this.$store.dispatch("admin/searchPermits", {
        active: this.active,
        address: this.address,
        permitId: this.permitId,
        email: this.email,
        end: this.range.end,
        start: this.range.start,
        phoneNo: this.phoneNo,
        type: this.type,
      });
    },
    formatPhone(fon: any) {
      this.phoneNo = formatPhoneNumber(fon.detail.value) ?? fon.detail.value;
    },
    async download() {
      const element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
          encodeURIComponent(await downloadCSV(this.getPermits))
      );
      element.setAttribute("download", `${this.todaysDate()}_permit_table.csv`);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    doSearch(a: any, b: any) {
      console.log("do search! TODO!!");
      console.log(a);
      console.log(b);
    },
    async openDialog(permitId: string) {
      const modal = await modalController.create({
        componentProps: {
          permit: this.getPermits
            ? this.getPermits.find((p: FirePermit) => p.permitId == permitId)
            : null,
        },
        component: PermitModal,
      });
      modal.present();
    },
    tableLoadingFinish(elements: any) {
      this.table.isLoading = false;
      Array.prototype.forEach.call(elements, (element: any) => {
        if (element.classList.contains("quick-btn")) {
          element.addEventListener("click", () => {
            this.openDialog(element.dataset.id);
          });
        }
      });
    },
  },
});
</script>
<style >
.gbp-table,
.gbp-table.card {
  overflow-x: scroll;
  background: #f4f5f8;
  padding-top: 0px;
  min-height: 400px;
}
.gbp-table .table .thead-dark {
  background: #222328;
  background-color: #222328;
}
.table tr {
  user-select: text;
}
.table td {
  background-color: white;
}
.table .thead-dark th {
  color: #fff;
  background-color: #222328 !important;
  border-color: #222328;
}

@media screen and (min-width: 576px) {
  .limit-width {
    max-width: 400px;
  }
}
</style>