
import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonInput,
  IonLabel,
  IonList,
  IonRow,
} from "@ionic/vue";
import {
  saveOutline,
  lockOpenOutline,
  lockClosedOutline,
} from "ionicons/icons";

import { defineComponent, ref } from "vue";
import { useForm, useField } from "vee-validate";

import { sixCharacters } from "../../utils/form.validators";
import InputError from "../permit/InputError.vue";

export default defineComponent({
  name: "UserSettings",
  props: {
    submitHandler: null, // SubmissionHandler<Record<string, any>>
    loading: {
      type: Boolean,
      default: false,
    },
    errorTxt: {
      type: String,
      default: "",
    },
    success: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    IonButton,
    IonCol,
    IonGrid,
    IonItem,
    IonInput,
    IonLabel,
    IonList,
    IonRow,
    InputError
  },
  setup(props: any) {
    const loaded = ref(false);
    const showErrors = ref(false);
    const { handleSubmit } = useForm();
    const onSubmit = handleSubmit(props.submitHandler);

    const { value: password, errorMessage: passError } = useField(
      "password",
      sixCharacters
    );
    const { value: vpassword, errorMessage: vpassError } = useField(
      "vpassword",
      sixCharacters
    );

    return {
      loaded,
      password,
      vpassword,
      passError,
      vpassError,
      onSubmit,
      showErrors,
      saveOutline,
      lockOpenOutline,
      lockClosedOutline,
    };
  },
  computed: {
    getUser(): any {
      return this.$store.state.admin.myUser;
    },
  },
  mounted() {
    // we need to wait for the store to get crap outta storage.
    setTimeout(() => {
      this.loaded = true;
    }, 300);
  },
  watch: {
    success(val) {
      if (val) {
        this.password = '';
        this.vpassword = '';
        this.showErrors = false;
      }
    }
  }
});
