import { County } from "@/models/county";
import { FirebaseService } from "./../services/firebase.service";
import { Module } from "vuex";
import { ExcludedDate } from "@/models/excluded";
import { dateMixin } from "@/utils/date.format";
import { getPermitNumber } from "@/utils/form.utils";

interface PermitModuleState {
  initComplete: boolean;
  counties: County[];
  lastCDoc: any; // for infinite list of counties
  selectedCounty: County;
  selectedCountyOrdinance: any;
  selectedCountyExcludedDates: ExcludedDate[];
  canApply: boolean;
  formData: any; // TODO isn't this that GBPSavedData class
  completedPermit: any;
  latestPermits: any[];
  scrollTop: number;
}

const getDefaultState = () => {
  return {
    initComplete: false,
    counties: [] as any,
    lastCDoc: null as any, // for infinite list of counties
    selectedCounty: null as any,
    selectedCountyOrdinance: null as any,
    selectedCountyExcludedDates: [] as any,
    canApply: false,
    formData: null as any, // TODO isn't this that GBPSavedData class
    completedPermit: null as any,
    latestPermits: [] as any,
    scrollTop: 0,
  };
};

const firebaseService = new FirebaseService();

export const permitModule: Module<PermitModuleState, {}> = {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    async resetStorage() {
      await localStorage.clear();
    },
    clearAll({ commit, dispatch }) {
      dispatch("resetStorage");
      commit("resetStore");
    },
    async saveFromListSelectedCounty({ commit, dispatch }, county) {
      await commit("saveSelectedCounty", county);
      // if county changes, load related info.
      dispatch("getSelectedCountyOrdinance"); // maybe don't need this right away?
      dispatch("getSelectedCountyExcludedDates");
      dispatch("getLatestPermits");
    },
    async setFormData({ commit }, formData) {
      commit("setFormData", formData);
    },
    async setFormDataNoSave({ commit }, formData) {
      commit("setFormData", formData);
      // no save, used for values like accept terms.
    },
    async initFromStorage({ state, commit, dispatch }) {
      if (!state.initComplete) {
        commit("completeInit");
        try {
          // just easier having init all in one place.
          dispatch("admin/listenForUser", null, { root: true });
          let county: any = await localStorage.getItem("gbpCounty");
          const formData = await localStorage.getItem("gbpFormData");
          if (county) {
            county = JSON.parse(county);
            await dispatch("getSelectedCounty", county.id);
            dispatch("getSelectedCountyOrdinance");
            dispatch("getSelectedCountyExcludedDates");
          }
          if (formData) {
            commit("setFormData", JSON.parse(formData));
          }
        } catch (err) {
          console.log(`could not init from storage ${err}`);
        }
      }
    },
    async searchCounties({ commit }, search: string) {
      const snapshot = await firebaseService.searchCounties(search, null);
      if (snapshot) {
        commit("setCounties", snapshot);
      }
    },
    async getCounties({ state, commit }, search: string) {
      const snapshot = await firebaseService.searchCounties(
        search,
        state.lastCDoc
      );
      if (snapshot) {
        commit("AddToCounties", snapshot);
      }
    },
    async getSelectedCounty({ commit }, countyId) {
      const snapshot = await firebaseService.getCounty(countyId);
      if (snapshot) {
        commit("setSelectedCounty", snapshot);
      }
    },
    async getSelectedCountyOrdinance({ state, commit }) {
      if (state.selectedCounty) {
        // typescript null why o why.
        const snapshot = await firebaseService.getCountyOrdinance(
          state.selectedCounty.id ?? ""
        );
        if (snapshot) {
          commit("setSelectedCountyOrdinance", snapshot);
        }
      }
    },
    async getSelectedCountyExcludedDates({ state, commit }) {
      if (state.selectedCounty) {
        // typescript null why o why.
        const snapshot = await firebaseService.getCountyExcludedDates(
          state.selectedCounty.id ?? ""
        );
        if (snapshot) {
          commit("setSelectedCountyExcludedDates", snapshot);
        }
      }
    },
    async getLatestPermits({ state, commit }) {
      try {
        if (state.selectedCounty && state.formData && state.formData!.email) {
          // typescript null why o why.
          const snapshot = await firebaseService.getMyPermits(
            state.selectedCounty.id ?? "",
            "email",
            state.formData!.email
          );
          const snapshot2 = await firebaseService.getMyPermits(
            state.selectedCounty.id ?? "",
            "phoneNo",
            state.formData!.phoneNo
          );
          commit("setLatestPermits", { snapshot, snapshot2 });
        }
      } catch (error) {
        console.log("error on getLatestPermits");
        console.log(error);
      }
    },
    async submitPermitApplication(
      { state, commit, dispatch },
      formData
    ): Promise<string> {
      try {
        if (formData && state.selectedCounty) {
          formData.state = "Georgia";
          formData.permitId = getPermitNumber();
          formData.submittedAt = new Date();
          formData.method = "web"; // web sms or phone.
          Object.keys(formData).forEach((key) =>
            formData[key] === undefined ? delete formData[key] : {}
          );
          const mockPermit = await firebaseService.submitPermitApplication(
            state.selectedCounty.id ?? "",
            formData
          );
          if (mockPermit) {
            await commit("setcompletedPermit", mockPermit);
          }
          await dispatch("getLatestPermits");
          return "";
        } else {
          return "could not submit permit";
        }
      } catch (error) {
        return error instanceof Error
          ? error.toString()
          : "error on submit application.";
      }
    },
    setScroll({ commit }, scrollTop) {
      commit("setScroll", scrollTop);
    },
  },
  mutations: {
    completeInit(state) {
      state.initComplete = true;
    },
    resetStore(state) {
      Object.assign(state, getDefaultState());
    },
    AddToCounties(state, snapshot) {
      if (snapshot.size == 0) {
        // state.lastCDoc = null
        return;
        // TODO , dont let it page to nothing!
      }
      // state.counties = [];
      let tempCount = 0; // clouser?
      snapshot.forEach((doc: any) => {
        // doc.data() is County type.
        // console.log(`${doc.id} => ${doc.data()}`);
        const c = doc.data();
        tempCount++;
        c.id = doc.id; // have to slap it on
        state.counties.push(c);
        if (tempCount == snapshot.size) {
          // last element. for paging.
          state.lastCDoc = doc;
        }
      });
    },
    setCounties(state, snapshot) {
      state.lastCDoc = null; // clear for scroll
      state.counties = [];
      let tempCount = 0; // clouser?
      snapshot.forEach((doc: any) => {
        const c = doc.data();
        tempCount++;
        c.id = doc.id; // have to slap it on
        state.counties.push(c);
        if (tempCount == snapshot.size) {
          // last element. for paging.
          state.lastCDoc = doc;
        }
      });
    },
    setFormData(state, formData) {
      Object.keys(formData).forEach((key) =>
        formData[key] === undefined ? delete formData[key] : {}
      );
      state.formData = Object.assign(state.formData ?? {}, formData);
      localStorage.setItem("gbpFormData", JSON.stringify(state.formData));
    },
    setcompletedPermit(state, permit) {
      // // I think its an array of one.
      // const c = doc.data();
      // c.id = doc.id // have to slap it on

      // its preformatted.
      state.completedPermit = permit;
    },
    setLatestPermits(state, { snapshot, snapshot2 }) {
      if (snapshot.empty && snapshot2.empty) {
        state.latestPermits = [];
        state.canApply = true;
        return;
      }
      const temp: any[] = [];
      const temp2: any[] = [];
      snapshot.forEach((doc: any) => {
        // doc.data() is FirePermit type.
        const c = doc.data();
        c.id = doc.id; // have to slap it on
        if (c.complete) {
          temp.push(c); // only push the complete ones!
        }
      });
      snapshot2.forEach((doc: any) => {
        // doc.data() is FirePermit type.
        const c = doc.data();
        c.id = doc.id; // have to slap it on
        if (c.complete) {
          temp2.push(c); // only push the complete ones!
        }
      });
      const together = [...temp, ...temp2];
      const output: any[] = [];
      together.forEach((p) => {
        const index = output.findIndex((op) => op.permitId == p.permitId);
        if (index == -1) {
          output.push(p);
        }
      });
      state.latestPermits = output;
      if (state.latestPermits.length >= 5) {
        state.canApply = false;
      } else {
        state.canApply = true;
      }
    },
    // if coming from firebase, use this one.
    setSelectedCounty(state, snapshot) {
      snapshot.forEach((doc: any) => {
        // doc.data() is County type.
        const c = doc.data();
        c.id = doc.id; // have to slap it on
        state.selectedCounty = c;
        localStorage.setItem("gbpCounty", JSON.stringify(c));
      });
    },
    // needs to be a pre formatted county.
    saveSelectedCounty(state, county) {
      state.selectedCounty = county;
      localStorage.setItem("gbpCounty", JSON.stringify(county));
    },
    setSelectedCountyExcludedDates(state, snapshot) {
      state.selectedCountyExcludedDates = [];
      snapshot.forEach((doc: any) => {
        // doc.data() is ExcludedDate type.
        const c = doc.data();
        c.id = doc.id; // have to slap it on
        state.selectedCountyExcludedDates.push(c);
      });
    },
    setSelectedCountyOrdinance(state, snapshot) {
      snapshot.forEach((doc: any) => {
        // doc.data() is Ordinance type.
        const c = doc.data();
        c.id = doc.id; // have to slap it on
        state.selectedCountyOrdinance = c;
      });
    },
    setScroll(state, scrollTop) {
      state.scrollTop = scrollTop;
    },
  },
  getters: {
    reason(state): string {
      let reason = "";
      if (
        state.selectedCountyExcludedDates &&
        state.selectedCountyExcludedDates.length
      ) {
        state.selectedCountyExcludedDates.forEach((d: ExcludedDate) => {
          // is same AS today.
          if (dateMixin.methods.isSameAsToday(d.date?.seconds ?? 0)) {
            reason = d.reason ?? "";
          }
        });
      }
      if (state.selectedCounty && !state.selectedCounty.enabled) {
        reason = `Sorry, we do not currently offer permits for ${state.selectedCounty.name}. Please contact your local Fire Department directly.`;
      }
      // also check thats not later than 6pm or before 8am
      if (dateMixin.methods.isNotDuringDay()) {
        reason =
          "Sorry, permits are only available during daylight hours between 8:00AM and 6:00PM.";
      }

      return reason;
    },
    areAvailable(state, getters): boolean {
      return !getters.reason;
    },
  },
};
