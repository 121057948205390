/*
COUNTY TEMP PERMIT MODEL
 {
    additionalLocationInfo "in my backyard"
    address "111 Somewhere Blvd"
    city "Banks"
    confirmCity  true
    existingSmallGardenSpot false
    firstName "Liam"
    handPiledVegetation false
    ipAddress "72.133.11.11"
    lastName "McNally"
    method web
    email cat@mailinatorcom
    permitId "123-1231232123'1321212"
    permitType ACD
    phoneNo "5555555555"
    state "georgia"
    acceptedTerms: true
    submittedAt {seconds, nanoseconds}
    zip "30547"
 }
*/

import { FirestoreDate } from "./permit";
  export class TempFirePermit {
    constructor(
          public acceptedTerms?: boolean,
          public additionalLocationInfo?: string,
          public address?: string,
          public city?: string,
          public complete?: boolean,
          public confirmCity?: boolean,
          public confirmCounty?: boolean,
          public countyId?: string,
          public cToken?: string, // don't need this, but convienient for formData
          public email?: string,
          public existingSmallGardenSpot?: boolean,
          public firstName?: string,
          public handPiledVegetation?: boolean,
          public ipAddress?: string,
          public lastName?: string,
          public method?: string, // sms web phone
          public permitId?: string, // client generated.
          public permitDocId?: string,
          public permitType?: string,
          public phoneNo?: string,
          public state?: string,
          public submittedAt?: FirestoreDate, // this is how dates come back!
          public zip?: string,
          public id?: string
    ) { }
  }
  
  // Firestore data converter
  export const tempPermitConverter = {
    toFirestore: function(permit: TempFirePermit) {
      return {
        acceptedTerms: permit.acceptedTerms,
        additionalLocationInfo: permit.additionalLocationInfo,
        address: permit.address,
        city: permit.city,
        complete: permit.complete,
        confirmCity: permit.confirmCity,
        confirmCounty: permit.confirmCounty,
        countyId: permit.countyId,
        cToken: permit.cToken,
        email: permit.email,
        existingSmallGardenSpot: permit.existingSmallGardenSpot,
        firstName: permit.firstName,
        handPiledVegetation: permit.handPiledVegetation,
        ipAddress: permit.ipAddress,
        lastName: permit.lastName,
        method: permit.method,
        permitId: permit.permitId,
        permitDocId: permit.permitDocId,
        permitType: permit.permitType,
        phoneNo: permit.phoneNo,
        state: permit.state,
        submittedAt: permit.submittedAt,
        zip: permit.zip,
        id: permit.id,
      };
    },
    // firebase.firestore.QuerySnapshot<County>
    fromFirestore: function(snapshot: any) {
      const data = snapshot.data();
      return new TempFirePermit(
          data.acceptedTerms,
          data.additionalLocationInfo,
          data.address,
          data.city,
          data.complete,
          data.confirmCity,
          data.confirmCounty,
          data.countyId,
          data.cToken,
          data.email,
          data.existingSmallGardenSpot,
          data.firstName,
          data.handPiledVegetation,
          data.ipAddress,
          data.lastName,
          data.method,
          data.permitId,
          data.permitDocId,
          data.permitType,
          data.phoneNo,
          data.state,
          data.submittedAt,
          data.zip,
          data.id
      );
    },
  };
  