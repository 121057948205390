<template>
  <form
    @submit.prevent="
      onSubmit(vales);
      showErrors = true;
    "
  >
    <ion-grid>
      <ion-row class="ion-padding-top" />
      <ion-row>
        <ion-col size-lg="4" size-sm="2"> </ion-col>
        <ion-col size-lg="4" size-sm="8"  size="12" class="ion-text-center ion-padding">
          <h2 class="ion-no-margin">
            {{ $t("forgot.forgotMessage") }}
          </h2>
        </ion-col>
        <ion-col size-lg="4" size-sm="2" > </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-lg="4" size-sm="2" > </ion-col>
        <ion-col size-lg="4"  size-sm="8"  size="12">
          <ion-list
            class="ion-no-padding"
            style="background: transparent"
          >
              <ion-item color="light">
                <ion-label position="floating">{{
                  $t("login.emailLabel")
                }}</ion-label>
                <ion-input
                  name="email"
                  type="email"
                  autocomplete
                  v-model="email"
                  enterkeyhint="done"
                  inputmode="email"
                ></ion-input>
              </ion-item>
              <input-error v-if="emailError" :show="showErrors" :msg="emailError"/>
          </ion-list>
        </ion-col>
        <ion-col size-lg="4"  size-sm="2" > </ion-col>
      </ion-row>

      <ion-row class="ion-padding-top">
        <ion-col size-lg="4" size-sm="2" > </ion-col>
        <ion-col size-lg="4" size-sm="8" size=12>
          <div>
            <ion-button
              color="primary"
              expand="full"
              type="submit"
              :disabled="loading"
              >{{ $t("forgot.sendEmail") }}</ion-button
            >
          </div>
        </ion-col>
        <ion-col size-lg="4" size-sm="2"> </ion-col>
      </ion-row>
      <back-button-link route="/login" />
      <ion-row class="ion-padding"> </ion-row>
    </ion-grid>
  </form>
</template>
<script lang='ts'>
import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonInput,
  IonLabel,
  IonList,
  IonRow,
} from "@ionic/vue";

import { defineComponent, ref } from "vue";
import BackButtonLink from "../permit/BackButtonLink.vue";
import { useForm, useField } from "vee-validate";

import { validateEmail } from "../../utils/form.validators";
import InputError from "../permit/InputError.vue";

export default defineComponent({
  name: "ForgotForm",
  props: {
    submitHandler: null, // SubmissionHandler<Record<string, any>>
    loading: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    IonButton,
    IonCol,
    IonGrid,
    IonItem,
    IonInput,
    IonLabel,
    IonList,
    IonRow,
    BackButtonLink,
    InputError,
  },
  setup(props) {
    const showErrors = ref(false);

    const { handleSubmit } = useForm();
    const onSubmit = handleSubmit(props.submitHandler);

    const { value: email, errorMessage: emailError } = useField(
      "email",
      validateEmail
    );
    return {
      email,
      emailError,
      onSubmit,
      showErrors,
    };
  },
  mounted() {
    // we need to wait for the store to get crap outta storage.
    setTimeout(() => {
      if (this.getSavedFormData && this.getSavedFormData.username) {
        this.email = this.getSavedFormData.username;
      }
    }, 100);
  },
  computed: {
    getSavedFormData(): any {
      return this.$store.state.permit.formData;
    },
  }
});
</script>
