<template>
  <ion-page>
    <ion-content class="ion-padding-top" color="light">
      <ion-grid  style="min-height: 75vh">
        <ion-row class="ion-padding-horizontal ion-padding-top">
          <ion-col size-lg="2"> </ion-col>
          <ion-col size-lg="8" size="12">
            <h1 class="ion-no-margin ion-text-start">
              {{ $t("admin.countyBurnOrdinance") }}
            </h1>
          </ion-col>
          <ion-col size-lg="2"> </ion-col>
        </ion-row>
        <ion-row class="ion-padding-top ion-padding-horizontal">
          <ion-col size-lg="2"> </ion-col>
          <ion-col size-lg="8" size="12">
            <h4 class="ion-no-margin ion-text-start">
              {{ $t("admin.ordinanceDescription") }}
            </h4>
          </ion-col>
          <ion-col size-lg="2"> </ion-col>
        </ion-row>
        <ion-row class="ion-padding-top ion-padding-horizontal ion-justify-items-end">
          <ion-col size-lg="2"> </ion-col>
          <ion-col  size-lg="8" size="12" class="flex">
            <ion-button
              color="secondary"
              style="margin: 0px 0px 0px auto"
              @click="goToPrintPage()"
            >
              {{$t("admin.printVersion")}}
              <ion-icon style="padding-left: 8px" :icon="printOutline"
            /></ion-button>
          </ion-col>
          <ion-col size-lg="2"> </ion-col>
        </ion-row>
        <ion-row class="ion-justify-items-center">
          <ion-col size-lg="2"> </ion-col>
          <ion-col
            size-lg="8"
            size="12"
           
            class="ion-padding"
          >
            <div v-if="adminCountyOrdinance">
              <ordinance-form :ordinance="adminCountyOrdinance" />
            </div>
          </ion-col>
          <ion-col size-lg="2"> </ion-col>
        </ion-row>
        <ion-row> </ion-row>
      </ion-grid>
      <gbp-footer />
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonButton, IonContent, IonGrid, IonIcon, IonCol, IonRow, IonPage } from "@ionic/vue";
import { defineComponent } from "vue";
import { printOutline } from "ionicons/icons";
import GbpFooter from "@/components/Footer.vue";
import OrdinanceForm from "@/components/admin/OrdinanceForm.vue";

export default defineComponent({
  name: "CountyOrdinance",
  components: {
    IonButton,
    IonContent,
    IonGrid,
    IonIcon,
    IonCol,
    IonRow,
    IonPage,
    GbpFooter,
    OrdinanceForm,
  },
  setup() {
    return { printOutline }
  },
  computed: {
    adminCountyOrdinance(): any {
      return this.$store.state.admin.adminCountyOrdinance;
    },
    adminCounty(): any {
      return this.$store.state.admin.adminCounty;
    },
  },
  mounted() {
    setTimeout(() => {
      if (!this.adminCountyOrdinance) {
        this.$store.dispatch("admin/getAdminCountyOrdinance");
      }
    }, 100);
  },
  methods: {
    goToPrintPage() {
      this.$router.push("/ordinancepreview?id=" + this.adminCounty.id)
    }
  }
});
</script>