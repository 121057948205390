
import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonInput,
  IonLabel,
  IonList,
  IonRow,
} from "@ionic/vue";

import { defineComponent, ref } from "vue";
import { useForm, useField } from "vee-validate";

import { sixCharacters, validateEmail } from "../../utils/form.validators";
import InputError from "../permit/InputError.vue";

export default defineComponent({
  name: "LoginForm",
  props: {
    submitHandler: null, // SubmissionHandler<Record<string, any>>
    loading: {
      type: Boolean,
      default: false,
    },
    errorTxt: {
      type: String,
      default: "",
    },
  },
  components: {
    IonButton,
    IonCol,
    IonGrid,
    IonItem,
    IonInput,
    IonLabel,
    IonList,
    IonRow,
    InputError,
  },
  setup(props) {
    const showErrors = ref(false);

    const { handleSubmit } = useForm();
    const onSubmit = handleSubmit(props.submitHandler);

    const { value: username, errorMessage: userError } = useField(
      "username",
      validateEmail
    );
    const { value: password, errorMessage: passError } = useField(
      "password",
      sixCharacters
    );

    let t: any;
    return {
      username,
      password,
      userError,
      passError,
      onSubmit,
      showErrors,
      t,
    };
  },
  mounted() {
    // we need to wait for the store to get crap outta storage.
    setTimeout(() => {
      if (this.getSavedFormData && this.getSavedFormData.username) {
        this.username = this.getSavedFormData.username;
      }
    }, 100);
  },
  computed: {
    getSavedFormData(): any {
      return this.$store.state.permit.formData;
    },
  },
  methods: {
    saveEmailLocally(val: any) {
      if (this.t) {
        clearTimeout(this.t);
      }
      this.t = setTimeout(() => {
        this.$store.dispatch("permit/setFormData", {
          username: val.detail.value,
        });
      }, 2000);
    },
  },
});
