/*
INVOICE MODEL
    {
            "periodStart": 1625068213,
            "total": 0,
            "dueDate": null,
            "periodEnd": 1625068213,
            "invoicePdf": "https://pay.stripe.com/invoice/acct_1J7L0HEvm6Wqcvot/invst_JlcvkSogIzKKnXIcIupIq9H3Ku0SjO1/pdf",
            "paid": true,
            "number": "A70534FF-0001",
            "status": "paid",
            "hostedInvoiceUrl": "https://invoice.stripe.com/i/acct_1J7L0HEvm6Wqcvot/invst_JlcvkSogIzKKnXIcIupIq9H3Ku0SjO1"
    }

    INVOICE RESPONSE MODEL
{
    "has_more": false,
    "data": [
        {
            "periodStart": 1625068213,
            "total": 0,
            "dueDate": null,
            "periodEnd": 1625068213,
            "invoicePdf": "https://pay.stripe.com/invoice/acct_1J7L0HEvm6Wqcvot/invst_JlcvkSogIzKKnXIcIupIq9H3Ku0SjO1/pdf",
            "paid": true,
            "number": "A70534FF-0001",
            "status": "paid",
            "hostedInvoiceUrl": "https://invoice.stripe.com/i/acct_1J7L0HEvm6Wqcvot/invst_JlcvkSogIzKKnXIcIupIq9H3Ku0SjO1"
        }
    ],
    "object": "list",
    "url": "/v1/invoices"
}
    
*/
export class Invoice {
    constructor(
        public periodStart?: number,
        public total?: number,
        public dueDate?: string,
        public periodEnd?: number,
        public invoicePdf?: string,
        public paid?: boolean,
        public number?: string,
        public status?: string,
        public hostedInvoiceUrl?: string,
    ) { }
}
