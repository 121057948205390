
import { IonButton, IonGrid, IonRow, IonCol, IonIcon } from "@ionic/vue";
import {
  chevronForwardCircleOutline,
  chevronBackCircleOutline,
  informationCircleOutline
} from "ionicons/icons";
import { defineComponent, reactive, computed, watch, nextTick } from "vue";
interface TableSetting {
  keyColumn: string;
  pageSize: number;
}
interface TableColumn {
  isKey: string;
  field: string;
}
export default defineComponent({
  name: "DataTableLite",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
  },
  emits: [
    "return-checked-rows",
    "do-search",
    "is-finished",
    "pagedown",
    "pageup",
    "pagesize"
  ],
  props: {
    isLoading: {
      type: Boolean,
      require: true,
    },
    isReSearch: {
      type: Boolean,
      require: true,
    },
    title: {
      type: String,
      default: "",
    },
    canpageup: {
      type: Boolean,
      default: false,
    },
    canpagedown: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    rows: {
      type: Array,
      default: () => {
        return [];
      },
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 100,
    },
    page: {
      type: Number,
      default: 1,
    },
    sortable: {
      type: Object,
      default: () => {
        return {
          order: "id",
          sort: "asc",
        };
      },
    },
  },
  computed: {
    tinyScreen(): boolean {
      if (!window || !window.screen) {
       return false
      } else {
        return window.screen.availWidth < 600
      }
    }
  },
  methods: {
    updatePageSize(cool: any) {
      this.$emit("pagesize", Number(cool))
    },
  },
  setup(props, { emit }) {
    const setting: TableSetting = reactive({
      keyColumn: computed(() => {
        let key = "";
        Object.assign(props.columns).forEach((col: TableColumn) => {
          if (col.isKey) {
            key = col.field;
          }
        });
        return key;
      }),
      pageSize: props.pageSize,
    });

    const doSort = (order: string) => {
      let sort = "asc";
      if (order == props.sortable.order) {
        if (props.sortable.sort == "asc") {
          sort = "desc";
        }
      }
      emit("do-search", order, sort);
    };

    watch(
      () => props.rows,
      () => {
        if (props.isReSearch) {
          //   setting.page = 1;
        }
        nextTick(function () {
          const localElement = document.getElementsByClassName("is-rows-el");
          emit("is-finished", localElement);
        });
      }
    );

    return {
      setting,
      doSort,
      chevronForwardCircleOutline,
      chevronBackCircleOutline,
      informationCircleOutline
    };
  },
});
