<template>
  <form
    @submit.prevent="
      onSubmit(vales);
      showErrors = true;
    "
  >
    <ion-grid>
      <ion-row class="ion-padding-top" />
      <ion-row>
        <ion-col size-lg="4" size-sm="2"> </ion-col>
        <ion-col
          size-lg="4"
          size-sm="8"
          size="12"
          class="ion-text-center ion-padding"
        >
          <h2 class="ion-no-margin">
            {{ $t("login.loginMessage") }}
          </h2>
        </ion-col>
        <ion-col size-lg="4" size-sm="2"> </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-lg="4" size-sm="2"> </ion-col>
        <ion-col size-lg="4" size-sm="8" size="12">
          <ion-list class="ion-no-padding" style="background: transparent">
            <ion-item color="light">
              <ion-label position="floating">{{
                $t("login.emailLabel")
              }}</ion-label>
              <ion-input
                name="username"
                type="email"
                @ionChange="saveEmailLocally"
                autocomplete
                v-model="username"
                enterkeyhint="next"
                inputmode="email"
              ></ion-input>
            </ion-item>
            <input-error v-if="userError" :show="showErrors" :msg="userError" />
            <ion-item color="light">
              <ion-label position="floating">{{
                $t("login.passwordLabel")
              }}</ion-label>
              <ion-input
              autocomplete
                name="password"
                type="password"
                v-model="password"
                enterkeyhint="done"
              ></ion-input>
            </ion-item>
            <input-error
              v-if="errorTxt || passError"
              :show="showErrors || errorTxt"
              :msg="errorTxt || passError"
            />
          </ion-list>
        </ion-col>
        <ion-col size-lg="4" size-sm="2"> </ion-col>
      </ion-row>
      <ion-row class="ion-padding-top">
        <ion-col size-lg="4" size-sm="2"> </ion-col>
        <ion-col size-lg="4" size-sm="8" size="12">
          <div>
            <ion-button
              color="primary"
              expand="full"
              type="submit"
              :disabled="loading"
              >{{ $t("login.loginButton") }}</ion-button
            >
          </div>
        </ion-col>
        <ion-col size-lg="4" size-sm="2"> </ion-col>
      </ion-row>
      <ion-row class="ion-padding">
        <ion-col size-lg="4" size-sm="2"> </ion-col>
        <ion-col size-lg="4" size-sm="8" size="12">
          <ion-text
            class="auto my-pointer ion-text-center"
            color="light"
            @click="() => $router.push('/forgot')"
          >
            <a style="text-decoration: underline">
              {{ $t("forgot.forgot") }}</a
            ></ion-text
          >
        </ion-col>
        <ion-col size-lg="4" size-sm="2"> </ion-col>
      </ion-row>
      <ion-row class="ion-padding"> </ion-row>
    </ion-grid>
  </form>
</template>
<script lang='ts'>
import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonInput,
  IonLabel,
  IonList,
  IonRow,
} from "@ionic/vue";

import { defineComponent, ref } from "vue";
import { useForm, useField } from "vee-validate";

import { sixCharacters, validateEmail } from "../../utils/form.validators";
import InputError from "../permit/InputError.vue";

export default defineComponent({
  name: "LoginForm",
  props: {
    submitHandler: null, // SubmissionHandler<Record<string, any>>
    loading: {
      type: Boolean,
      default: false,
    },
    errorTxt: {
      type: String,
      default: "",
    },
  },
  components: {
    IonButton,
    IonCol,
    IonGrid,
    IonItem,
    IonInput,
    IonLabel,
    IonList,
    IonRow,
    InputError,
  },
  setup(props) {
    const showErrors = ref(false);

    const { handleSubmit } = useForm();
    const onSubmit = handleSubmit(props.submitHandler);

    const { value: username, errorMessage: userError } = useField(
      "username",
      validateEmail
    );
    const { value: password, errorMessage: passError } = useField(
      "password",
      sixCharacters
    );

    let t: any;
    return {
      username,
      password,
      userError,
      passError,
      onSubmit,
      showErrors,
      t,
    };
  },
  mounted() {
    // we need to wait for the store to get crap outta storage.
    setTimeout(() => {
      if (this.getSavedFormData && this.getSavedFormData.username) {
        this.username = this.getSavedFormData.username;
      }
    }, 100);
  },
  computed: {
    getSavedFormData(): any {
      return this.$store.state.permit.formData;
    },
  },
  methods: {
    saveEmailLocally(val: any) {
      if (this.t) {
        clearTimeout(this.t);
      }
      this.t = setTimeout(() => {
        this.$store.dispatch("permit/setFormData", {
          username: val.detail.value,
        });
      }, 2000);
    },
  },
});
</script>
