import { createStore } from 'vuex'
import { adminModule } from './admin'
import { permitModule } from './permit'

export const store = createStore({
  modules: {
      admin: adminModule,
      permit: permitModule
  }
})
