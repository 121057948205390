
import { IonGrid, IonRow, IonCol, IonLabel, modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import { createOutline } from "ionicons/icons";

export default defineComponent({
  name: "PermitHeadingInfo",
  components: {
    IonCol,
    IonGrid,
    IonLabel,
    IonRow,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      createOutline,
    };
  },
  computed: {
    selectedCounty(): any {
      return this.$store.state.permit.selectedCounty;
    },
    formData(): any {
      return this.$store.state.permit.formData;
    },
  },
  methods: {
    // async openModal() {
    //   if (!this.disabled) {
    //     const modal = await modalController.create({
    //       component: Modal,
    //     });
    //     modal.present();
    //     const result = await modal.onDidDismiss();
    //     if (result && result.data) {
    //       this.$store.dispatch("admin/changeActiveCounty", result.data);
    //     }
    //   }
    // },
  },
});
