import { DateTime } from "luxon";

export const chatboxSVG = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512"><title>By SMS</title><path d="M408,64H104a56.16,56.16,0,0,0-56,56V312a56.16,56.16,0,0,0,56,56h40v80l93.72-78.14a8,8,0,0,1,5.13-1.86H408a56.16,56.16,0,0,0,56-56V120A56.16,56.16,0,0,0,408,64Z" style="fill:none;stroke:#000;stroke-linejoin:round;stroke-width:32px"/><circle cx="160" cy="216" r="32"/><circle cx="256" cy="216" r="32"/><circle cx="352" cy="216" r="32"/></svg>';
export const phoneSVG = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512"><title>By Phone</title><path d="M451,374c-15.88-16-54.34-39.35-73-48.76C353.7,313,351.7,312,332.6,326.19c-12.74,9.47-21.21,17.93-36.12,14.75s-47.31-21.11-75.68-49.39-47.34-61.62-50.53-76.48,5.41-23.23,14.79-36c13.22-18,12.22-21,.92-45.3-8.81-18.9-32.84-57-48.9-72.8C119.9,44,119.9,47,108.83,51.6A160.15,160.15,0,0,0,83,65.37C67,76,58.12,84.83,51.91,98.1s-9,44.38,23.07,102.64,54.57,88.05,101.14,134.49S258.5,406.64,310.85,436c64.76,36.27,89.6,29.2,102.91,23s22.18-15,32.83-31a159.09,159.09,0,0,0,13.8-25.8C465,391.17,468,391.17,451,374Z" style="fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:32px"/></svg>';
export const webSVG = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512"><title>By Web</title><path d="M256,48C141.13,48,48,141.13,48,256s93.13,208,208,208,208-93.13,208-208S370.87,48,256,48Z" style="fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:32px"/><path d="M256,48c-58.07,0-112.67,93.13-112.67,208S197.93,464,256,464s112.67-93.13,112.67-208S314.07,48,256,48Z" style="fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:32px"/><path d="M117.33,117.33c38.24,27.15,86.38,43.34,138.67,43.34s100.43-16.19,138.67-43.34" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><path d="M394.67,394.67c-38.24-27.15-86.38-43.34-138.67-43.34s-100.43,16.19-138.67,43.34" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/><line x1="256" y1="48" x2="256" y2="464" style="fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:32px"/><line x1="464" y1="256" x2="48" y2="256" style="fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:32px"/></svg>';

export const tableColumns = [
    {
      label: "Permit #",
      field: "permitId",
      width: "10%",
      isKey: true,
    },
    {
      label: " ",
      field: "method",
      width: "2%",
      display: function (row: any) {
        if (row.method == 'sms') {
          return '<div class="flex"><div class="auto o-87">' + chatboxSVG + '</div></div>'
        } else if (row.method == 'phone') {
          return '<div class="flex"><div class="auto o-87">' + phoneSVG + '</div></div>'
        } else {
          return '<div class="flex"><div class="auto o-87">' + webSVG + '</div></div>'
        }
      },
    },
    {
      label: "Type",
      field: "permitType",
      width: "3%",
      //sortable: true,
    },
    {
      label: "Expiry",
      field: "submittedAt",
      sortable: true,
      width: "15%",
      display: (row: any) => {
        const valid =
          DateTime.fromSeconds(row.submittedAt.seconds).plus({days: 1}) >
          DateTime.now()
        return (
          "<div " +
          'class="' +
          (valid ? "text--success" : "text--danger") +
          '" ' +
          ">" +
          DateTime.fromSeconds(row.submittedAt.seconds)
            .plus({ days: 1 })
            .toLocaleString(DateTime.DATETIME_FULL) +
          "</div>"
        );
      },
    },
    {
      label: "Phone No",
      field: "phoneNo",
      width: "18%",
      //sortable: true,
      display: function (row: any) {
        return (
          `<a class="text--accent" href="tel:${row.phoneNo}">${row.phoneNo}</a>`
        );
      },
    },
    {
      label: "Address",
      field: "address",
      width: "22%",
      display: function (row: any) {
        return (
          "<div>" +
          row.address +
          ", " +
          row.city +
          (row.city ? ", " : ' ') +
          row.zip +
          "</div>"
        );
      },
    },
    {
      label: "Name",
      field: "lastName",
      width: "8%",
      display: function (row: any) {
        return "<div>" + row.firstName + " " + row.lastName + "</div>";
      },
    },
    {
      label: "Email",
      field: "email",
      width: "18%",
      display: function (row: any) {
        return '<div style="word-wrap: anywhere">' + (row.email ? row.email : 'N/A') +  '</div>';
      },
    },
    {
      label: "Details",
      width: "5%",
      display: function (row: any) {
        return (
          '<a type="button" data-id="' +
          row.permitId +
          '" class="my-pointer text--primary-dark is-rows-el quick-btn my-underline">DETAILS</a>'
        );
      },
    },
  ];