
import BillingDetails from "@/components/settings/BillingDetails.vue";
import GbpFooter from "@/components/Footer.vue";
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonFooter
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "BillingPage",
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonFooter,
    GbpFooter,
    BillingDetails
  },
  mounted() {
    this.$store.dispatch("permit/initFromStorage");
  },
  computed: {
    adminCounty(): any {
      return this.$store.state.admin.adminCounty;
    },
  },
});
