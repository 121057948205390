<template>
  <ion-page>
    <ion-tabs
      @ionTabsWillChange="beforeTabChange"
      @ionTabsDidChange="afterTabChange"
    >
      <div>
        <logo-header/>
      </div>
      <div class="ion-text-center ion-padding-horizontal">
        <ion-text color="primary">
          <h4 class="mt-0 mb-2">
            {{ adminCounty ? adminCounty.name : $t("settings.noSelectedCounty") }}
          </h4>
        </ion-text>
      </div>
      <ion-tab-bar :selectedTab="selectedTab" slot="top">
        <ion-tab-button tab="profile" href="/settings/profile">
          <ion-icon :icon="personOutline"></ion-icon>
          <ion-label>{{ $t("menu.profile") }}</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="billing" href="/settings/billing">
          <ion-icon :icon="cardOutline"></ion-icon>
          <ion-label>{{ $t("menu.billing") }}</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="counties" href="/settings/counties">
          <ion-icon :icon="locationOutline"></ion-icon>
          <ion-label>{{ $t("menu.counties") }}</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import {
  IonIcon,
  IonLabel,
  IonPage,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonText
} from "@ionic/vue";
import { locationOutline, cardOutline, personOutline } from "ionicons/icons";
import LogoHeader from "../../components/LogoHeader.vue";


export default defineComponent({
  name: "SettingsTabsPage",
  components: {
    IonIcon,
    IonLabel,
    IonPage,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonText,
    LogoHeader
  },
  setup() {
    const selectedTab = ref('billing')
    const beforeTabChange = () => {
      // do something before tab change
    };
    const afterTabChange = () => {
      // do something after tab change
    };
    return {
      selectedTab,
      locationOutline,
      cardOutline,
      personOutline,
      beforeTabChange,
      afterTabChange,
    };
  },
  mounted() {
    this.$store.dispatch("permit/initFromStorage");
    if (this.$route.path.includes('counties')) {
      this.selectedTab = 'counties'
    } else if (this.$route.path.includes('billing')) {
      this.selectedTab = 'billing'
    }
  },
  computed: {
    adminCounty(): any {
      return this.$store.state.admin.adminCounty;
    },
  },
});
</script>