<template>
  <ion-header>
    <ion-toolbar
      color="secondary" class="pt-2 pb-2"
    >
      <ion-title>{{$t("onboard.termsModal")}}</ion-title>
      <ion-buttons slot="end">
        <ion-icon
          @click="closeMe(null)"
          class="pr-2 my-pointer"
          button
          :icon="closeOutline"
          size="large"
        />
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding-horizontal">
    <div class="ion-padding">
      <pre style="white-space: break-spaces;">{{terms}}</pre>
    </div>
  </ion-content>
</template>

<script lang='ts'>
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonToolbar,
  modalController
} from "@ionic/vue";
import { defineComponent, ref} from "vue";
import { closeOutline } from "ionicons/icons";
import { TERMS } from "@/constants";

export default defineComponent({
  name: "Modal",
  setup() {
    const search = ref('')
    return { closeOutline, search };
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonToolbar
  },
  computed: {
    terms(): any {
      return TERMS
    }
  },
  methods: {
    closeMe() {
      modalController.dismiss()
    },
  }
});
</script>