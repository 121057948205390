<template>
  <ion-page>
    <ion-content color="light">
      <logo-header />
      <ion-grid>
      <ion-row>
        <ion-col size-lg="2" size="auto"> </ion-col>
        <ion-col size-lg="8" class="ion-text-start">
          <h2>{{ $t("onboard.welcome") }}</h2>
          <h4 class="ion-padding-top">As a regional administrator, you will be able review permits and admin controls for the region{{myCounties && myCounties.length ? 's' : ''}} you have access to. We have detected the following region{{myCounties && myCounties.length ? 's' : ''}} associated with your account:</h4>
        </ion-col>
        <ion-col size-lg="2" size="auto"> </ion-col>
      </ion-row>
        <ion-row class="ion-padding-horizontal" v-if="myCounties">
          <ion-col size-md="2"> </ion-col>
          <ion-col size-md="8" size="12">
            <ion-list>
              <ion-item
              lines="none"
                v-for="c in myCounties"
                :key="c.name"
              >
                <ion-label>
                  <h2>{{ c.name }}</h2>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col size-md="2"> </ion-col>
        </ion-row>

      <ion-row>
        <ion-col size-lg="2" size="auto"> </ion-col>
        <ion-col size-lg="8" class="ion-text-start">
          <h4 class="ion-padding-top mb-1">{{ $t("onboard.desc1") }}
         {{ $t("onboard.desc") }} <a :href="termsLink" target="_blank" class="my-pointer">{{$t("onboard.tc")}}</a></h4>
        </ion-col>
        <ion-col size-lg="2" size="auto"> </ion-col>
      </ion-row>
      <billing-form/>
        <back-button-link route="/login"/>
        <ion-row class="ion-padding" />
      </ion-grid>
      <gbp-footer />
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonGrid,
  IonCol,
  IonRow,
  IonList,
  IonItem,
  IonLabel,
  IonPage,
} from "@ionic/vue";
import { defineComponent } from "vue";
import LogoHeader from "../components/LogoHeader.vue";
import { dateMixin } from "./../utils/date.format";
import BackButtonLink from './../components/permit/BackButtonLink.vue';
import { useSnackbarPlugin } from "snackbar-vue";
import BillingForm from "@/components/onboard/Onboard.vue";
import GbpFooter from "@/components/Footer.vue";
import { TERMS_LINK } from "@/constants";

export default defineComponent({
  name: "OnboardPage",
  mixins: [dateMixin],
  components: {
    BackButtonLink,
    BillingForm,
    IonCol,
    IonRow,
    IonGrid,
    IonContent,
    IonPage,
    IonList,
    IonItem,
    IonLabel,
    GbpFooter,
    LogoHeader,
  },
  setup() {
    const snack = useSnackbarPlugin();
    return { snack }
  },
  computed: {
    termsLink(): any {
      return TERMS_LINK;
    },
    myCounties(): any {
      return this.$store.state.admin.myCounties;
    },
  }
});
</script>
