
import {
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonPage,
  IonText,
} from "@ionic/vue";
import { ref, defineComponent } from "vue";
import { calendarOutline, informationCircleOutline, readerOutline } from "ionicons/icons";
import LogoHeader from "../../components/LogoHeader.vue";

export default defineComponent({
  name: "CountyAdmin",
  components: {
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonLabel,
    IonPage,
    IonText,
    LogoHeader,
  },
  setup() {
    const selectedTab = ref('permits')
    const beforeTabChange = () => {
      // do something before tab change
    };
    const afterTabChange = () => {
      // do something after tab change
    };
    return {
      selectedTab,
      calendarOutline,
      informationCircleOutline,
      readerOutline,
      beforeTabChange,
      afterTabChange,
    };
  },
  mounted() {
    this.$store.dispatch("permit/initFromStorage");
    if (this.$route.path.includes('details')) {
      this.selectedTab = 'details'
    } else if (this.$route.path.includes('calendar')) {
      this.selectedTab = 'calendar'
    } else if (this.$route.path.includes('ordinance')) {
      this.selectedTab = 'ordinance'
    }
  },
  computed: {
    adminCounty(): any {
      return this.$store.state.admin.adminCounty;
    },
  },
});
