
import { defineComponent, reactive, ref } from "vue";
import DataTableLite from "./DataTableLite.vue";
import { tableColumns } from "../../utils/table.columns";
import { dateMixin } from "../../utils/date.format";
import { DatePicker } from "v-calendar";
import {
  IonGrid,
  IonButton,
  IonCol,
  IonCheckbox,
  IonItem,
  IonIcon,
  IonRow,
  IonSearchbar,
  modalController,
} from "@ionic/vue";
import { downloadOutline } from "ionicons/icons";
import PermitModal from "./PermitModal.vue";
import { downloadCSV } from "../../utils/json2csv";
import { FirePermit } from "@/models/permit";
import {
  formatPhoneNumber,
  validateEmail,
  validatePhone,
} from "@/utils/form.validators";

export default defineComponent({
  name: "DateTable",
  mixins: [dateMixin],
  components: {
    IonButton,
    IonCheckbox,
    IonCol,
    IonGrid,
    IonIcon,
    IonItem,
    IonRow,
    IonSearchbar,
    DataTableLite,
    DatePicker,
  },
  computed: {
    adminCounty(): any {
      return this.$store.state.admin.adminCounty;
    },
    getPermits(): any {
      return this.$store.state.admin.permits;
    },
    pageSize(): number {
      return this.$store.state.admin.pageSize;
    },
    canPageUp(): boolean {
      return this.$store.state.admin.moreAfter;
    },
    canPageDown(): boolean {
      return this.$store.state.admin.moreBefore;
    },
    validEmail(): boolean {
      if (!this.email) {
        return true;
      }
      return typeof validateEmail(this.email) == "boolean";
    },
    validPhone(): boolean {
      if (!this.phoneNo) {
        return true;
      }
      return typeof validatePhone(this.phoneNo) == "boolean";
    },
  },
  setup() {
    const d = new Date();
    const s = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 7); // store takes care of start of day, end of day.
    const e = new Date(d.getFullYear(), d.getMonth(), d.getDate());
    const range = {
      start: s,
      end: e,
    };
    const masks = {
      input: "YYYY-MM-DD",
    };
    const email = ref("");
    const permitId = ref("");
    const address = ref("");
    const phoneNo = ref("");
    const type = ref("");
    const active = ref(false);
    const table = reactive({
      isLoading: false,
      isReSearch: false,
      columns: tableColumns,
      sortable: {
        order: "submittedAt",
        sort: "desc",
      },
    });
    return {
      table,
      masks,
      range,
      email,
      permitId,
      address,
      phoneNo,
      type,
      active,
      downloadOutline,
    };
  },
  mounted() {
    setTimeout(async () => {
      if (this.adminCounty) {
        this.searchPermits();
      }
    }, 3000);
  },
  methods: {
    async setPageSize(pageSize: number) {
      if (pageSize) {
        await this.$store.dispatch("admin/setPageSize", pageSize);
        this.searchPermits();
      }
    },
    pageBackPermits() {
      this.$store.dispatch("admin/pageBackPermits", {
        active: this.active,
        address: this.address,
        permitId: this.permitId,
        email: this.email,
        end: this.range.end,
        start: this.range.start,
        phoneNo: this.phoneNo,
        type: this.type,
      });
    },
    pageNextPermits() {
      this.$store.dispatch("admin/pageNextPermits", {
        active: this.active,
        address: this.address,
        permitId: this.permitId,
        email: this.email,
        end: this.range.end,
        start: this.range.start,
        phoneNo: this.phoneNo,
        type: this.type,
      });
    },
    searchPermits() {
      this.$store.dispatch("admin/searchPermits", {
        active: this.active,
        address: this.address,
        permitId: this.permitId,
        email: this.email,
        end: this.range.end,
        start: this.range.start,
        phoneNo: this.phoneNo,
        type: this.type,
      });
    },
    formatPhone(fon: any) {
      this.phoneNo = formatPhoneNumber(fon.detail.value) ?? fon.detail.value;
    },
    async download() {
      const element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
          encodeURIComponent(await downloadCSV(this.getPermits))
      );
      element.setAttribute("download", `${this.todaysDate()}_permit_table.csv`);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    doSearch(a: any, b: any) {
      console.log("do search! TODO!!");
      console.log(a);
      console.log(b);
    },
    async openDialog(permitId: string) {
      const modal = await modalController.create({
        componentProps: {
          permit: this.getPermits
            ? this.getPermits.find((p: FirePermit) => p.permitId == permitId)
            : null,
        },
        component: PermitModal,
      });
      modal.present();
    },
    tableLoadingFinish(elements: any) {
      this.table.isLoading = false;
      Array.prototype.forEach.call(elements, (element: any) => {
        if (element.classList.contains("quick-btn")) {
          element.addEventListener("click", () => {
            this.openDialog(element.dataset.id);
          });
        }
      });
    },
  },
});
