
import {
  IonButton,
  IonContent,
  IonGrid,
  IonCol,
  IonRow,
  IonPage,
  IonText,
} from "@ionic/vue";
import { defineComponent } from "vue";
import LogoHeader from "../components/LogoHeader.vue";
import { dateMixin } from "./../utils/date.format";
// import BackButtonLink from './../components/BackButtonLink.vue';
import { useSnackbarPlugin } from "snackbar-vue";

export default defineComponent({
  name: "CompletePage",
  mixins: [dateMixin],
  components: {
    // BackButtonLink,
    IonButton,
    IonCol,
    IonRow,
    IonGrid,
    IonContent,
    IonPage,
    IonText,
    LogoHeader,
  },
  setup() {
    const snack = useSnackbarPlugin();
    return { snack };
  },
  computed: {
    getcompletedPermit(): any {
      return this.$store.state.permit.completedPermit;
    },
    selectedCounty(): any {
      return this.$store.state.permit.selectedCounty;
    },
    selectedCountyOrdinance(): any {
      return this.$store.state.permit.selectedCountyOrdinance;
    },
    completedDate(): string {
      // this is going to be js date.
      if (this.getcompletedPermit) {
        return this.addDayReadableDate(
          this.getcompletedPermit.submittedAt
            ? this.getcompletedPermit.submittedAt.getTime() / 1000
            : 0
        );
      }
      return ''
    },
  },
  mounted() {
    this.$store.dispatch("permit/initFromStorage");
    setTimeout(() => {
      if (this.getcompletedPermit) {
        this.snack.show({
          position: "bottom",
          text: this.errorTxt ? this.errorTxt : this.$t("complete.permitCreated"),
          time: 2500,
          close: true,
        });
      } else {
        this.$router.push("/check");
      }
    }, 250);
  },
});
