
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import MainMenu from "./components/admin/MainMenu.vue";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    MainMenu,
  },
  setup() {
    const loading = ref(true);
    return { loading };
  },
  computed: {
    getUser(): any {
      return this.$store.state.admin.myUser;
    },
    getCustomer(): any {
      return this.$store.state.admin.myCustomer;
    },
  },
  async mounted() {
    this.$store.dispatch("permit/initFromStorage");
    this.checkLocale();
    setTimeout(() => {
      this.loading = false;
    }, 200);
    if (this.isAuthdPage(this.$route.path)) {
      if (!this.getUser) {
         // if we don't have user and authed page, lets wait a second.
          await this.timeout(3000);
      }
      if (!this.getUser) {
        this.$router.push("/login" );
      } else if (
        (this.getUser && !this.getCustomer) ||
        (this.getUser && !this.getCustomer.stripeCustomerId)
      ) {
        if (this.$route.path == "/onboard" || this.getCustomer) {
          return;
        }
        this.$router.push('/onboard');
      }
    }
  },
  beforeCreate() {
    this.$router.beforeEach(async (to, from, next) => {
      const authdPath = this.isAuthdPage(to.path);
      if (authdPath && !this.getUser) {
        // if we don't have user and authed page, lets wait a second.
        await this.timeout(1000);
      }

      if (authdPath && !this.getUser) {
        next({ path: "/login" });
      } else if (
        (authdPath && this.getUser && !this.getCustomer) ||
        (authdPath && this.getUser && !this.getCustomer.stripeCustomerId)
      ) {
        if (to.path == "/onboard" || this.getCustomer) {
          next();
        }
        next({ path: "/onboard" });
      } else {
        next();
      }
    });
  },
  methods: {
    checkLocale() {
      try {
        const lang = navigator.language.toString().toUpperCase();
        if (lang.includes("ES")) {
          this.$i18n.locale = "es";
        } else {
          this.$i18n.locale = "en";
        }
      } catch (error) {
        console.log("could not check local", error);
      }
    },
    timeout(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    isAuthdPage(path: string): boolean {
      return [
        "/onboard",
        "/dashboard",
        "/dashboard/permits",
        "/dashboard/weather",
        "/dashboard/counties",
        "/admin/details",
        "/admin/calendar",
        "/admin/ordinance",
        "/settings/profile",
        "/settings/billing",
        "/settings/counties",
      ].findIndex((i) => i === path) > -1
    }
  },
});
