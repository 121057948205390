<template>
  <ion-grid>
    <ion-row />
    <ion-row :style="{'opacity': customer.locked ? '0.5' : '1.0'}">
      <ion-col size-lg="3"> </ion-col>
      <ion-col size-lg="6" size="12" class="light">
        <h5><strong>{{$t("billing.paymentDetails")}}</strong></h5>
        <div class="ion-padding-vertical">
          <ion-list>
            <ion-item>
              {{$t("billing.cardType")}}
              <div slot="end" class="flex" style="justify-items: center">
                <payment-card-icon
                  v-if="customer && customer.stripeCardType"
                  :type="customer.stripeCardType"
                />
                <div v-if="customer && customer.stripeLastFour">
                  &nbsp;&nbsp; {{$t("billing.cardEnding")}} {{ customer.stripeLastFour }}
                </div>
                <div v-if="loaded && (!customer || !customer.stripeLastFour) && !customer.locked">
                  &nbsp;&nbsp; {{$t("billing.pleaseUpdate")}}
                </div>
              </div>
            </ion-item>
            <ion-item>
              {{$t("billing.cardExpiry")}}
              <div v-if="customer && customer.stripeExpMonth" slot="end">
                {{ customer.stripeExpMonth }}/{{ customer.stripeExpYear }}
              </div>
            </ion-item>
            <ion-item>
              {{$t("billing.billingInterval")}}
              <div slot="end">{{$t("billing.monthly")}}</div>
            </ion-item>
            <ion-item lines="none">
              <div style="opacity: 0.7">
                {{$t("billing.processedByStripe")}}
              </div>
              <div slot="end">
                <img
                  width="128"
                  height="64"
                  src="https://cycleclub.nyc3.digitaloceanspaces.com/icons/stripe-black.svg"
                  class="auto align-self-center"
                  contain
                />
              </div>
            </ion-item>
            <ion-item lines="none">
              <ion-button slot="end" @click="openModal" :disabled="!customer || customer.locked"
                >{{$t("billing.updatePaymentInfo")}}
              </ion-button>
            </ion-item>
          </ion-list>
        </div>
      </ion-col>
      <ion-col size-lg="3"> </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-lg="3"> </ion-col>
      <ion-col size-lg="6" size="12">
        <h5><strong>{{$t("billing.latestInvoices")}}</strong></h5>
        <div class="ion-padding-vertical">
          <invoice-block v-for="i in invoices" :key="i.dueDate" :invoice="i" />
          <ion-list v-if="loaded && invoices && invoices.length == 0">
            <ion-item lines="none" disabled>
              <h2 class="ion-text-center w100">
               {{$t("billing.noInvoices")}}
              </h2>
            </ion-item>
          </ion-list>
        </div>
      </ion-col>
      <ion-col size-lg="2"> </ion-col>
    </ion-row>
  </ion-grid>
</template>
<script lang='ts'>
import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonList,
  IonRow,
  modalController,
} from "@ionic/vue";
import {
  saveOutline,
  lockOpenOutline,
  lockClosedOutline,
} from "ionicons/icons";

import { defineComponent, ref } from "vue";
import PaymentCardIcon from "./CardIcon.vue";
import UpdateBillingModal from "./UpdateBillingModal.vue";
import InvoiceBlock from "./InvoiceBlock.vue";

export default defineComponent({
  name: "BillingDetails",
  components: {
    IonButton,
    IonCol,
    IonGrid,
    IonItem,
    IonList,
    IonRow,
    PaymentCardIcon,
    InvoiceBlock,
  },
  setup(props: any) {
    const loaded = ref(false);
    const submitting = ref(false);
    const success = ref(false);
    const errorTxt = ref("");
    const edit = ref(false);
    let t: any;
    return {
      t,
      loaded,
      submitting,
      success,
      errorTxt,
      edit,
      saveOutline,
      lockOpenOutline,
      lockClosedOutline,
    };
  },
  computed: {
    customer(): any {
      return this.$store.state.admin.myCustomer;
    },
    invoices(): any {
      return this.$store.state.admin.myInvoices;
    },
  },
  mounted() {
    // we need to wait for the store to get crap outta storage.
    this.$store.dispatch("admin/getInvoices");
    setTimeout(() => {
      this.loaded = true;
    }, 500);
  },
  methods: {
    async openModal() {
      const modal = await modalController.create({
        component: UpdateBillingModal,
      });
      modal.present();
    },
  },
});
</script>
