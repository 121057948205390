
import { IonContent, IonGrid, IonRow, IonCol, IonPage } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import GbpFooter from "@/components/Footer.vue";
import PermitDashboard from "@/components/admin/PermitDashboard.vue";
import DataTable from "@/components/admin/DataTable.vue";

export default defineComponent({
  name: "SearchPermitsPage",
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    GbpFooter,
    PermitDashboard,
    DataTable
  },
  setup() {
    const content = ref();
    return { content };
  },
  methods: {
    handleScroll($event: any) {
      this.$store.dispatch("permit/setScroll", $event.detail.scrollTop )
    },
    scrollTop() {
      this.content.$el.scrollToTop();
    }
  }
});
