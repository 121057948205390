
import {
  IonButton,
  IonCol,
  IonGrid,
  IonList,
  IonItem,
  IonRow,
  IonText,
} from "@ionic/vue";
import { documentTextOutline } from "ionicons/icons";
import { defineComponent, ref } from "vue";
import { dateMixin } from "../../utils/date.format";
import BackButtonLink from "./BackButtonLink.vue";

export default defineComponent({
  name: "CheckExistingList",
  mixins: [dateMixin],
  components: {
    IonButton,
    IonCol,
    IonGrid,
    IonList,
    IonItem,
    IonRow,
    IonText,
    BackButtonLink,
  },
  computed: {
    selectedCounty(): any {
      return this.$store.state.permit.selectedCounty;
    },
    getSavedFormData(): any {
      return this.$store.state.permit.formData;
    },
    getLatestPermits(): any {
      return this.$store.state.permit.latestPermits;
    },
    today(): any {
      return this.todaysDate();
    },
  },
  setup() {
    const loading = ref(true);
    return {
      loading,
      documentTextOutline,
    };
  },
  async mounted() {
    setTimeout(async () => {
      if (this.selectedCounty) {
        await this.$store.dispatch(
          "permit/getLatestPermits",
          this.selectedCounty.id
        );
        this.loading = false;
      }
    }, 2000);
  },
});
