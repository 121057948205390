<template>
  <ion-grid>
    <ion-row class="ion-padding">
      <ion-col size-md="2" size="auto"> </ion-col>
      <ion-col size-md="8" class="ion-text-start">
        <h2 class="ion-no-margin">
          {{ $t("apply.existingPermits") }}
          {{
            getSavedFormData &&
            getSavedFormData.firstName &&
            getSavedFormData.lastName
              ? " for " +
                getSavedFormData.firstName +
                " " +
                getSavedFormData.lastName
              : ""
          }}
          {{ selectedCounty ? "in " + selectedCounty.name : "" }}
        </h2>
      </ion-col>
      <ion-col size-md="2" size="auto"> </ion-col>
    </ion-row>
    <ion-row class="ion-padding" />
    <ion-row>
      <ion-col size-md="2" size="auto"> </ion-col>
      <ion-col size-md="8" class="ion-text-start ion-padding-horizontal">
        <div
          v-if="!getLatestPermits || getLatestPermits.length == 0"
          class="flex white ion-padding"
          style="flex-direction: column"
        >
          <div v-if="!loading">
            <ion-text class="ion-text-center" color="secondary">
              <h1>No active permits found.</h1>
              <h4>
                <strong
                  >You may apply for a new burn permit for {{ today }}
                </strong>
              </h4>
            </ion-text>
          </div>
          <div v-if="loading">
            <ion-text class="ion-text-center" color="secondary">
              <h1>Loading..</h1>
              <h4>
              </h4>
            </ion-text>
          </div>
        </div>
        <div
          v-if="getLatestPermits && getLatestPermits.length > 0"
          class="flex white ion-padding"
          style="flex-direction: column"
        >
          <div>
            <ion-text class="ion-text-start" color="secondary">
              <ion-text
                class="ion-text-center ion-padding-horizontal"
                color="secondary"
              >
                <h1>
                  You already have {{ getLatestPermits.length }} permit{{
                    getLatestPermits.length == 1 ? "" : "s"
                  }}
                  for today.
                </h1>
              </ion-text>
            </ion-text>
          </div>
        </div>
        <ion-list>
          <ion-item v-for="p in getLatestPermits" :key="p.permitId" lines="none">
            <ion-label>
              <h2>{{ p.address ?? '' + ", " + p.city ?? '' + ", " + p.zip ?? '' }}</h2>
              <p>
                Expires
                {{
                  addDayReadableDate(p.submittedAt ? p.submittedAt.seconds : 0)
                }}
              </p>
              <p>Permit # {{ p.permitId }}</p>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
      <ion-col size-md="2" size="auto"> </ion-col>
    </ion-row>
    <ion-row class="ion-padding"> </ion-row>
    <ion-row class="ion-padding-top">
      <ion-col size-md="4"> </ion-col>
      <ion-col size-md="4" class="flex">
        <ion-button
          color="primary"
          :disabled="getLatestPermits && getLatestPermits.length >= 5"
          @click="$router.push('/review')"
          >{{
            getLatestPermits.length > 0
              ? $t("apply.applyAnotherButton")
              : $t("apply.applyButton")
          }}</ion-button
        >
      </ion-col>
      <ion-col size-md="4"> </ion-col>
    </ion-row>
    <back-button-link route="/permit" />
  </ion-grid>
</template>
<script lang='ts'>
import {
  IonButton,
  IonCol,
  IonGrid,
  IonList,
  IonItem,
  IonRow,
  IonText,
} from "@ionic/vue";
import { documentTextOutline } from "ionicons/icons";
import { defineComponent, ref } from "vue";
import { dateMixin } from "../../utils/date.format";
import BackButtonLink from "./BackButtonLink.vue";

export default defineComponent({
  name: "CheckExistingList",
  mixins: [dateMixin],
  components: {
    IonButton,
    IonCol,
    IonGrid,
    IonList,
    IonItem,
    IonRow,
    IonText,
    BackButtonLink,
  },
  computed: {
    selectedCounty(): any {
      return this.$store.state.permit.selectedCounty;
    },
    getSavedFormData(): any {
      return this.$store.state.permit.formData;
    },
    getLatestPermits(): any {
      return this.$store.state.permit.latestPermits;
    },
    today(): any {
      return this.todaysDate();
    },
  },
  setup() {
    const loading = ref(true);
    return {
      loading,
      documentTextOutline,
    };
  },
  async mounted() {
    setTimeout(async () => {
      if (this.selectedCounty) {
        await this.$store.dispatch(
          "permit/getLatestPermits",
          this.selectedCounty.id
        );
        this.loading = false;
      }
    }, 2000);
  },
});
</script>
