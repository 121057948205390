/*
COUNTY PERMIT MODEL
 {
    additionalLocationInfo "in my backyard"
    address "111 Somewhere Blvd"
    city "Banks"
    existingSmallGardenSpot false
    firstName "Liam"
    handPiledVegetation false
    ipAddress "72.133.11.11"
    lastName "McNally"
    email cat@mailinatorcom
    permitId "123-1231232123'1321212"
    permitType ACD
    phoneNo "5555555555"
    state "georgia"
    acceptedTerms: true
    submittedAt {seconds, nanoseconds}
    zip "30547"
 }
*/

export class FirestoreDate {
    constructor(
    public seconds?: number,
    public nanoseconds?: number
    ) {}
}

export class FirePermit {
    constructor(
        public acceptedTerms?: boolean,
        public additionalLocationInfo?: string,
        public address?: string,
        public city?: string,
        public cToken?: string, // don't need this, but convienient for formData
        public email?: string,
        public existingSmallGardenSpot?: boolean,
        public firstName?: string,
        public handPiledVegetation?: boolean,
        public ipAddress?: string,
        public lastName?: string,
        public method?: string, // sms phone or web.
        public permitId?: string, // client generated.
        public permitType?: string,
        public phoneNo?: string,
        public state?: string,
        public submittedAt?: FirestoreDate, // this is how dates come back!
        public zip?: string,
        public id?: string
    ) { }
}

// Firestore data converter
export const permitConverter = {
    toFirestore: function (permit: FirePermit) {
        return {
            acceptedTerms: permit.acceptedTerms,
            additionalLocationInfo: permit.additionalLocationInfo,
            address: permit.address,
            city: permit.city,
            cToken: permit.cToken,
            email: permit.email,
            existingSmallGardenSpot: permit.existingSmallGardenSpot,
            firstName: permit.firstName,
            handPiledVegetation: permit.handPiledVegetation,
            ipAddress: permit.ipAddress,
            lastName: permit.lastName,
            method: permit.method,
            permitId: permit.permitId,
            permitType: permit.permitType,
            phoneNo: permit.phoneNo,
            state: permit.state,
            submittedAt: permit.submittedAt,
            zip: permit.zip,
            id: permit.id
        };
    },
    // firebase.firestore.QuerySnapshot<County>
    fromFirestore: function (snapshot: any, options: any) {
        const data = snapshot.data(options);
        return new FirePermit(
            data.acceptedTerms,
            data.additionalLocationInfo,
            data.address,
            data.city,
            data.cToken,
            data.email,
            data.existingSmallGardenSpot,
            data.firstName,
            data.handPiledVegetation,
            data.ipAddress,
            data.lastName,
            data.method,
            data.permitId,
            data.permitType,
            data.phoneNo,
            data.state,
            data.submittedAt,
            data.zip,
            data.id
        );
    }
};