
import { IonButton, IonGrid, IonRow, IonCol, IonIcon, IonLabel, modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import Modal3 from "./modal3.vue";
import { createOutline } from "ionicons/icons";
import { useSnackbarPlugin } from "snackbar-vue";

export default defineComponent({
  name: "InfoReviewBlock",
  components: {
    IonButton,
    IonCol,
    IonGrid,
    IonIcon,
    IonRow,
    IonLabel
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const snack = useSnackbarPlugin();
    return {
      snack,
      createOutline,
    };
  },
  computed: {
    formData(): any {
      return this.$store.state.permit.formData;
    },
  },
  methods: {
    async openModal3() {
      if (!this.disabled) {
        const modal = await modalController.create({
          componentProps: {
            initFormData: this.formData,
          },
          component: Modal3,
        });
        modal.present();
        const result = await modal.onDidDismiss();
        if (result && result.data) {
          this.$store.dispatch("permit/setFormData", result.data);
          this.snack.show({
            position: "bottom",
            text: this.$t("review.informationUpdated"),
            time: 2500,
            close: true,
          });
        }
      }
    },
  },
});
