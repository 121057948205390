<template>
  <div class="my-box">
    <div class="w100 flex" style="flex-direction: column">
      <div class="accent">
        <h6 class="text--white">{{ label }}</h6>
      </div>
      <h1 class="text--primary-dark" style="font-size: 64px; margin: auto">{{ value ?? '' }}</h1>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DashboardCard",
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      default: null,
    },
  },
});
</script>

<style scoped>
.my-box {
  width: 100%;
  height: 250px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: white;
  display: flex;
  text-align: center;
}
.my-box h1 {
  margin: auto;
  font-size: 64px;
}
@media screen and (min-width: 768px) {
  .my-box {
    max-width: 80vw;
    width: 80vw;
    margin: auto;
  }
}
@media screen and (min-width: 992px) {
  .my-box {
    max-width: 30vw;
    width: 270px;
    margin: auto;
  }
}
</style>
