
import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonList,
  IonRow,
  modalController,
} from "@ionic/vue";
import {
  saveOutline,
  lockOpenOutline,
  lockClosedOutline,
} from "ionicons/icons";

import { defineComponent, ref } from "vue";
import PaymentCardIcon from "./CardIcon.vue";
import UpdateBillingModal from "./UpdateBillingModal.vue";
import InvoiceBlock from "./InvoiceBlock.vue";

export default defineComponent({
  name: "BillingDetails",
  components: {
    IonButton,
    IonCol,
    IonGrid,
    IonItem,
    IonList,
    IonRow,
    PaymentCardIcon,
    InvoiceBlock,
  },
  setup(props: any) {
    const loaded = ref(false);
    const submitting = ref(false);
    const success = ref(false);
    const errorTxt = ref("");
    const edit = ref(false);
    let t: any;
    return {
      t,
      loaded,
      submitting,
      success,
      errorTxt,
      edit,
      saveOutline,
      lockOpenOutline,
      lockClosedOutline,
    };
  },
  computed: {
    customer(): any {
      return this.$store.state.admin.myCustomer;
    },
    invoices(): any {
      return this.$store.state.admin.myInvoices;
    },
  },
  mounted() {
    // we need to wait for the store to get crap outta storage.
    this.$store.dispatch("admin/getInvoices");
    setTimeout(() => {
      this.loaded = true;
    }, 500);
  },
  methods: {
    async openModal() {
      const modal = await modalController.create({
        component: UpdateBillingModal,
      });
      modal.present();
    },
  },
});
