
import { IonButton, IonContent, IonGrid, IonIcon, IonCol, IonRow, IonPage } from "@ionic/vue";
import { defineComponent } from "vue";
import { printOutline } from "ionicons/icons";
import GbpFooter from "@/components/Footer.vue";
import OrdinanceForm from "@/components/admin/OrdinanceForm.vue";

export default defineComponent({
  name: "CountyOrdinance",
  components: {
    IonButton,
    IonContent,
    IonGrid,
    IonIcon,
    IonCol,
    IonRow,
    IonPage,
    GbpFooter,
    OrdinanceForm,
  },
  setup() {
    return { printOutline }
  },
  computed: {
    adminCountyOrdinance(): any {
      return this.$store.state.admin.adminCountyOrdinance;
    },
    adminCounty(): any {
      return this.$store.state.admin.adminCounty;
    },
  },
  mounted() {
    setTimeout(() => {
      if (!this.adminCountyOrdinance) {
        this.$store.dispatch("admin/getAdminCountyOrdinance");
      }
    }, 100);
  },
  methods: {
    goToPrintPage() {
      this.$router.push("/ordinancepreview?id=" + this.adminCounty.id)
    }
  }
});
