
import {
  IonButton,
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { chevronBackOutline, printOutline } from "ionicons/icons";
import { dateMixin } from "@/utils/date.format";
import { PrintService } from "@/utils/dom.to.image";
 
export default defineComponent({
  name: "OrdinancePreview",
  mixins: [dateMixin],
  components: {
    IonButton,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonPage,
    IonIcon,
  },
  // TODO
  // LOAD THE APPRIOPRIATE ORDINANCE BASED ON THE ID IN THE PATH URL.
  mounted() {
    setTimeout(() => {
      if (!this.selectedCounty || !!this.selectedCountyOrdinance) {
        if (this.$route.query && this.$route.query.id) {
          this.$store.dispatch("permit/getSelectedCounty", this.$route.query.id);
        }
      }
    }, 500);
  },
  computed: {
    selectedCountyOrdinance(): any {
      return this.$store.state.permit.selectedCountyOrdinance || this.$store.state.admin.adminCountyOrdinance;
    },
    selectedCounty(): any {
      return this.$store.state.permit.selectedCounty || this.$store.state.admin.adminCounty;
    },
  },
  setup() {
    return { chevronBackOutline, printOutline };
  },
  methods: {
    print(componentName: any) {
      PrintService.print(componentName)
    }
  }
});
