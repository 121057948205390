<template>
  <ion-header>
    <ion-toolbar
      color="secondary"
      class="pt-2 pb-2"
    >
      <ion-title>{{ $t("permit.detailsModalTitle") }}</ion-title>
      <ion-buttons slot="end">
        <ion-icon
          @click="closeMe"
          class="ion-padding-end my-pointer"
          button
          :icon="closeOutline"
          size="large"
        />
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding-bottom ion-padding-horizontal">
    <ion-list>
      <item-row :caption="$t('permit.permitNumber')" :value="permit.permitId" />
      <item-row
        :caption="$t('permit.permitExpires')"
        :value="addDayReadableDate(permit.submittedAt.seconds)"
      />
      <item-row
        :caption="$t('permit.submittedAt')"
        :value="readableDate(permit.submittedAt.seconds)"
      />
      <item-row
        :caption="$t('permit.name')"
        :value="(permit.firstName ||  permit.lastName ) ? (permit.firstName + ' ' + permit.lastName) : 'N/A' "
      />
      <item-row
        :caption="$t('permit.address')"
        :value="permit.address + ' ' + permit.city + ' ' + permit.zip"
      />
      <ion-item lines="none" style="max-height: 40px">
        <div class="flex ion-justify-content-between w100">
          <div>{{ $t("permit.phoneNo") }}:</div>
          <div class="ion-text-end"><a v-if="permit.phoneNo" class="text--accent" :href="'tel:' + permit.phoneNo ">{{ permit.phoneNo }}</a></div>
        </div>
      </ion-item>
      <item-row :caption="$t('permit.email')" :value="permit.email ?? 'N/A'" />
      <item-row
        :caption="$t('permit.handPiledMin')"
        :value="permit.handPiledVegetation ? 'Yes' : 'No'"
      />
      <item-row
        :caption="$t('permit.existingGardenMin')"
        :value="permit.existingSmallGardenSpot ? 'Yes' : 'No'"
      />
      <item-row
        :caption="$t('permit.locationInfoMin')"
        :value="permit.additionalLocationInfo ?? 'none'"
      />
    </ion-list>
    <div class="ion-padding-horizontal ion-padding-bottom">
      <div class="ion-text-start">
        <ion-text color="primary" class="my-pointer" @click="download">
          <a style="text-decoration: underline; font-size: 12px">
            {{$t("permit.downloadPermit")}}
          </a>
        </ion-text>
      </div>
    </div>
  </ion-content>
</template>

<script lang='ts'>
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonText,
  IonTitle,
  IonToolbar,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { closeOutline, downloadOutline } from "ionicons/icons";
import { FirePermit } from "@/models/permit";
import ItemRow from "./ItemRow.vue";
import { dateMixin } from "@/utils/date.format";
import { downloadCSV } from "@/utils/json2csv";

export default defineComponent({
  mixins: [dateMixin],
  name: "PermitModal",
  setup() {
    return { closeOutline, downloadOutline };
  },
  props: {
    permit: {
      type: FirePermit,
      default: null,
    },
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonList,
    IonItem,
    IonIcon,
    IonText,
    IonTitle,
    IonToolbar,
    ItemRow,
  },

  methods: {
    closeMe() {
      modalController.dismiss();
    },
    async download() {
      const element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
          encodeURIComponent(await downloadCSV([this.permit]))
      );
      element.setAttribute("download", `${this.permit.permitId}_permit.csv`);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  },
});
</script>