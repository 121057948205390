import { DateTime } from "luxon";

// Date Mixin
export const dateMixin = {
  methods: {
    readableShortDate(seconds?: number): string {
      return seconds
        ? DateTime.fromSeconds(seconds).toLocaleString(DateTime.DATE_MED)
        : ""; // Oct 14, 1983
    },
    readableDate(seconds?: number): string {
      return seconds
        ? DateTime.fromSeconds(seconds).toLocaleString(DateTime.DATETIME_FULL)
        : ""; //  	October 14, 1983, 1:30 PM EDT
    },
    addDayReadableDate(seconds?: number): string {
      return seconds
        ? DateTime.fromSeconds(seconds)
            .plus({ days: 1 })
            .toLocaleString(DateTime.DATETIME_FULL)
        : ""; // 	October 15, 1983, 1:30 PM EDT
    },
    isSameAsToday(seconds: number) {
      return DateTime.now().hasSame(DateTime.fromSeconds(seconds), "day");
    },
    todaysDate() {
      return DateTime.now().toLocaleString(DateTime.DATE_MED);
    },
    yesterday() {
      return DateTime.now()
        .minus({ days: 1 })
        .toJSDate();
    },
    endOfDay(d: Date) {
      return DateTime.fromJSDate(d)
        .endOf("day")
        .toJSDate();
    },
    startOfDay(d: Date) {
      return DateTime.fromJSDate(d)
        .startOf("day")
        .toJSDate();
    },
    isNotDuringDay() {
      // check that current time in zone is between 8am and 6pm
      const now = DateTime.fromJSDate(new Date(), {
        zone: "America/New_York",
      });

      const eight = now.set({ hour: 8, minute: 0, second: 0 });
      const six = now.set({ hour: 18, minute: 0, second: 0 });

      return now < eight || now >= six;
    },
  },
};
