<template>
  <ion-page>
    <ion-content class="ion-padding-top" color="light">
      <ion-grid style="min-height: 80vh">
        <!-- <ion-row class="flex">
          <iframe class="auto" height="900px" width="900px" src="https://airquality.weather.gov/sectors/georgia.php"/>
        </ion-row> -->
        <ion-row>
          <ion-col size-lg="1"> </ion-col>
          <ion-col size-lg="10" size="12">
            <h1>{{$t("dashboard.weatherAlerts")}}</h1>
            <h4>{{$t("dashboard.reviewAlertsText")}}<a :href="'https://' + $t('dashboard.weatherApiLink')" target="_blank">{{$t("dashboard.weatherApiLink")}}</a></h4>
          </ion-col>
          <ion-col size-lg="1"> </ion-col>
        </ion-row>
        <ion-row/>
        <weather-alerts/>
      </ion-grid>
      <gbp-footer />
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";
import { defineComponent } from "vue";
import GbpFooter from "@/components/Footer.vue";
import WeatherAlerts from "@/components/admin/WeatherAlerts.vue";

export default defineComponent({
  name: "CountiesPage",
  components: {
    IonContent,
    IonCol,
    IonPage,
    IonGrid,
    IonRow,
    GbpFooter,
    WeatherAlerts,
  },
});
</script>
