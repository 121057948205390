
import CountySelect from "@/components/permit/CountySelect.vue";
import ArePermitsAvailable from "@/components/permit/ArePermitsAvailable.vue";
import {
  IonButton,
  IonContent,
  IonGrid,
  IonCol,
  IonRow,
  IonPage,
} from "@ionic/vue";
import { defineComponent } from "vue";
import LogoHeader from "../components/LogoHeader.vue";
import AncillaryInfo from "../components/permit/AncillaryInfo.vue";
import NoPhoneBanner from "../components/permit/NoPhoneBanner.vue";
// import BackButtonLink from '../components/permit/BackButtonLink.vue';
import GbpFooter from "@/components/Footer.vue";

export default defineComponent({
  name: "ApplicationPage",
  components: {
    AncillaryInfo,
    // BackButtonLink,
    IonButton,
    IonCol,
    IonRow,
    IonGrid,
    IonContent,
    IonPage,
    LogoHeader,
    CountySelect,
    ArePermitsAvailable,
    GbpFooter,
    NoPhoneBanner,
  },
  mounted() {
    this.$store.dispatch("permit/initFromStorage");
  },
  computed: {
    selectedCounty(): any {
      return this.$store.state.permit.selectedCounty;
    },
    excludedDates(): any[] {
      return this.$store.state.permit.selectedCountyExcludedDates;
    },
    areAvailable(): boolean {
      return this.$store.getters["permit/areAvailable"];
    },
    reason(): string {
      return this.$store.getters["permit/reason"];
    },
  },
  watch: {
    // didn't seem to be aware of changes otherwise
    selectedCounty(val) {
      // console.log('yo')
    },
  },
});
