
import { defineComponent } from "vue";
import { Invoice } from "./../../models/invoice";
import { IonGrid, IonRow, IonCol } from "@ionic/vue";
import { dateMixin } from "@/utils/date.format";

export default defineComponent({
  name: "InvoiceBlock",
  mixins: [dateMixin],
  components: {
    IonGrid,
    IonRow,
    IonCol,
  },
  props: {
    invoice: {
      type: Invoice,
      default: null,
    },
  },
  computed: {
    formatCurrency(): any {
      if (this.invoice && this.invoice.total) {
        return `$${(this.invoice.total / 100).toFixed(2)}`;
      }
      return "$0.00";
    },
    formatPeriodEnd(): any {
      if (this.invoice && this.invoice.periodEnd) {
        return this.readableShortDate(this.invoice.periodEnd);
      }
      return "";
    },
    formatPeriodStart(): any {
      if (this.invoice && this.invoice.periodStart) {
        return this.readableShortDate(this.invoice.periodStart);
      }
      return "";
    },
  },
});
