
import { defineComponent } from "vue";
import { IonButton, IonGrid, IonRow, IonCol, IonIcon } from "@ionic/vue";
import { chevronUpCircleOutline } from "ionicons/icons";
import { PRIVACY_POLICY_LINK, TERMS_LINK, CONTACT_LINK } from "@/constants";

export default defineComponent({
  name: "GbpFooter",
  components: {
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
  },
  props: {
    showbutton: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return { chevronUpCircleOutline };
  },
  computed: {
    termsLink() {
      return TERMS_LINK;
    },
    privacyLink() {
      return PRIVACY_POLICY_LINK;
    },
    contactLink() {
      return CONTACT_LINK;
    },
  }
});
