<template>
      <ion-menu v-if="getUser" side="end" menu-id="first" content-id="other">
        <ion-header>
          <ion-toolbar color="secondary" class="ion-padding-horizontal">
          <img
            src="/../assets/georgia-logo.svg"
            class="auto align-self-center"
            contain
          />
          </ion-toolbar>
        </ion-header>
        <ion-content id="other" class="ion-padding-vertical" color="light">
          <ion-list class="ion-no-padding"  style="background: transparent" >
            <ion-item color="light" @click="goNClose('/dashboard')" button>{{ $t("menu.dashboard") }}</ion-item>
            <ion-item v-if="isAdmin" color="light" @click="goNClose('/admin')" button>{{ $t("menu.admin") }}</ion-item>
            <ion-item v-if="isAdmin" color="light" @click="goNClose('/settings')" button>{{ $t("menu.settings") }}</ion-item>
            <ion-item color="light" @click="logout" lines="none" detail="false" button
              >{{ $t("menu.logout") }}</ion-item
            >
          </ion-list>
      </ion-content>
      </ion-menu>
</template>

<script lang='ts'>
import {
  IonContent,
  IonItem,
  IonList,
  IonMenu,
  IonHeader,
  IonToolbar,
  menuController
} from "@ionic/vue";
import { menuOutline } from "ionicons/icons";
import { defineComponent } from "vue";

export default defineComponent({
  name: "MainMenu",
  components: {
    IonContent,
    IonItem,
    IonList,
    IonMenu,
    IonHeader,
    IonToolbar,
  },
  setup() {
    return { menuOutline }
  },
  computed: {
    getUser() {
      return this.$store.state.admin.myUser
    },
    isAdmin(): boolean {
      return this.$store.state.admin.adminRole == 'admin'
    }
  },
  methods: {
    goNClose(route: string) {
      menuController.close('first');
      this.$router.push(route)
    },
    logout() {
      this.$store.dispatch('admin/logout')
      menuController.close('first');
      this.$router.push('/login')
    },
  }
});
</script>
