
import { defineComponent, ref } from "vue";
import { IonButton, IonItem, IonIcon, IonList, IonTextarea } from "@ionic/vue";
import { Ordinance } from "@/models/ordinance";
import { lockClosedOutline, lockOpenOutline } from "ionicons/icons";
import { dateMixin } from "@/utils/date.format";
import { useSnackbarPlugin } from "snackbar-vue";

export default defineComponent({
  name: "OrdinanceForm",
  mixins: [dateMixin],
  components: {
    IonButton,
    IonIcon,
    IonItem,
    IonList,
    IonTextarea,
  },
  props: {
    ordinance: {
      type: Ordinance,
      default: null,
    },
  },
  setup(props) {
    const touched = ref(false);
    const enabled = ref(false);
    const snack = useSnackbarPlugin();
    let all = ref("");
    if (props.ordinance && props.ordinance.sections) {
      props.ordinance.sections!.forEach((s) => {
        all = ref(all.value + s + "\n\n ");
      });
    }
    return {
      snack,
      all,
      enabled,
      touched,
      lockClosedOutline,
      lockOpenOutline,
    };
  },
  methods: {
    revert() {
      this.touched = false;
      if (this.ordinance && this.ordinance.sections) {
        this.all = "";
        this.ordinance.sections!.forEach((s) => {
          this.all = this.all + s + "\n\n ";
        });
      }
    },
    async checkForm() {
      this.enabled = false;
      const b = this.all.replace(/(\n\n)+/gi, "\n\n");
      const errorMsg = await this.$store.dispatch("admin/updateOrdinance", {
        id: this.ordinance.id,
        sections: b.split("\n\n"),
      });
      this.snack.show({
        position: "bottom",
        text: errorMsg ? errorMsg : this.$t("ordinance.changesSavedSuccessfully"),
        time: 3000,
        close: true,
      });
      this.enabled = true;
    },
  },
});
