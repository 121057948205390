
import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonInput,
  IonLabel,
  IonList,
  IonRow,
} from "@ionic/vue";

import { defineComponent, ref } from "vue";
import BackButtonLink from "../permit/BackButtonLink.vue";
import { useForm, useField } from "vee-validate";

import { validateEmail } from "../../utils/form.validators";
import InputError from "../permit/InputError.vue";

export default defineComponent({
  name: "ForgotForm",
  props: {
    submitHandler: null, // SubmissionHandler<Record<string, any>>
    loading: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    IonButton,
    IonCol,
    IonGrid,
    IonItem,
    IonInput,
    IonLabel,
    IonList,
    IonRow,
    BackButtonLink,
    InputError,
  },
  setup(props) {
    const showErrors = ref(false);

    const { handleSubmit } = useForm();
    const onSubmit = handleSubmit(props.submitHandler);

    const { value: email, errorMessage: emailError } = useField(
      "email",
      validateEmail
    );
    return {
      email,
      emailError,
      onSubmit,
      showErrors,
    };
  },
  mounted() {
    // we need to wait for the store to get crap outta storage.
    setTimeout(() => {
      if (this.getSavedFormData && this.getSavedFormData.username) {
        this.email = this.getSavedFormData.username;
      }
    }, 100);
  },
  computed: {
    getSavedFormData(): any {
      return this.$store.state.permit.formData;
    },
  }
});
