<template>
  <div
    class="ion-padding-horizontal ios-notch accent pt-3 w100 ion-hide-sm-down"
    style="overflow: hidden"
  >
    <div class="flex">
      <div class="flex w100" style="max-height: 10vh; position: relative">
        <ion-button
          v-show="getUser"
          @click="openMenu"
          class="my-pointer"
          fill="clear"
          style="position: absolute; top: 0; right: 0; color: white; z-index: 1"
        >
          <ion-text color="secondary-contrast">
            {{ getUsername }}
          </ion-text>
          <ion-icon
            class="ion-padding-start"
            color="secondary-contrast"
            :icon="menuOutline"
            size="large"
          ></ion-icon>
        </ion-button>
        <img
          alt="georgiaburnpermitslogo"
          :style="{
            height: tinyScreen || scrollTop > 50 ? '48px' : '84px',
            width: tinyScreen || scrollTop > 50 ? '329px' : '576px',
          }"
          style="transition: all 0.1s linear"
          @click="$router.push('/')"
          src="/../assets/georgia-logo.svg"
          class="auto align-self-center my-pointer"
          contain
        />
      </div>
    </div>
  </div>
  <div
    class="ios-notch px-2 accent py-2 w100 ion-hide-sm-up"
    style="overflow: hidden"
  >
    <div class="flex">
      <div class="flex w100" style="max-height: 10vh; position: relative;">
        <ion-button
          v-show="getUser"
          @click="openMenu"
          class="my-pointer"
          fill="clear"
          style="position: absolute; top: 0px; right: 0; color: white; z-index: 1"
        >
          <ion-text color="secondary-contrast ion-hide-sm-down">
            {{ getUsername }}
          </ion-text>
          <ion-icon
            class="ion-padding-start"
            color="secondary-contrast"
            :icon="menuOutline"
            size="large"
          ></ion-icon>
        </ion-button>
        <img
          style="height: 36px; width: 247px; transition: all 0.1s linear; position: relative;"
          :style="{
            right: getUser ? 'unset' : 'unset',
            margin: getUser ? 'auto' : 'auto',
          }"
          @click="$router.push('/')"
          src="/../assets/georgia-logo.svg"
          class="auto my-pointer"
          contain
        />
      </div>
    </div>
    where would this be
  </div>
</template>

<script lang="ts">
import { IonButton, IonIcon, IonText, menuController } from "@ionic/vue";
import { menuOutline } from "ionicons/icons";
import { defineComponent } from "vue";

export default defineComponent({
  name: "LogoHeader",
  components: {
    IonButton,
    IonIcon,
    IonText,
  },
  setup() {
    return { menuOutline };
  },
  computed: {
    getUser(): any {
      return this.$store.state.admin.myUser;
    },
    getUsername(): any {
      const a = this.getUser ? this.getUser.email.split("@") : [""];
      return a[0];
    },
    scrollTop() {
      return this.$store.state.permit.scrollTop;
    },
    tinyScreen(): boolean {
      if (!window || !window.screen) {
        return false;
      } else {
        return window.screen.availWidth < 600;
      }
    },
  },
  methods: {
    openMenu() {
      menuController.enable(true, "first");
      menuController.open("first");
    },
  },
  mounted() {
    this.$store.dispatch("permit/setScroll", 0);
  },
});
</script>
