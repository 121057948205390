<template>
  <form
    @submit.prevent="
      onSubmit(vales);
      showErrors = true;
    "
  >
  <ion-grid>
    <ion-row>
      <ion-col size-lg="3"> </ion-col>
      <ion-col size-lg="6" size="12">
        <h5 class="ion-padding-bottom"><strong>{{$t("settings.userDetails")}}</strong></h5>
        <ion-list v-if="loaded" class="ion-padding">
          <ion-item class="ion-no-padding">
            <ion-label position="floating">{{
              $t("login.emailLabel")
            }}</ion-label>
            <ion-input
              v-if="getUser"
              name="username"
              autocomplete
              readonly
              v-model="getUser.email"
              enterkeyhint="next"
              inputmode="email"
            ></ion-input>
          </ion-item>
          <ion-item class="ion-no-padding">
            <ion-label position="floating">{{$t("settings.newPassword")}}</ion-label>
            <ion-input
              name="newpassword"
              type="password"
              v-model="password"
              enterkeyhint="next"
            ></ion-input>
          </ion-item>
          <ion-item class="ion-no-padding">
            <ion-label position="floating">{{$t("settings.verifyPassword")}}</ion-label>
            <ion-input
              name="verifypassword"
              type="password"
              v-model="vpassword"
              enterkeyhint="done"
            ></ion-input>
          </ion-item>
          <input-error v-if="errorTxt || vpassError || passError"  :show="showErrors || errorTxt" :msg="errorTxt || vpassError || passError"/>
        </ion-list>
      </ion-col>
      <ion-col size-lg="3"> </ion-col>
    </ion-row>
    <ion-row class="ion-padding-top">
      <ion-col size-lg="3"> </ion-col>
      <ion-col size-lg="6" size="12">
        <div class="w100 flex">
          <ion-button
            color="primary"
            :disabled="!loaded"
            type="submit"
            style="margin: 0px 0px 0px auto"
            >{{$t("settings.updatePassword")}}</ion-button
          >
        </div>
      </ion-col>
      <ion-col size-lg="3"> </ion-col>
    </ion-row>
  </ion-grid>
  </form>
</template>
<script lang='ts'>
import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonInput,
  IonLabel,
  IonList,
  IonRow,
} from "@ionic/vue";
import {
  saveOutline,
  lockOpenOutline,
  lockClosedOutline,
} from "ionicons/icons";

import { defineComponent, ref } from "vue";
import { useForm, useField } from "vee-validate";

import { sixCharacters } from "../../utils/form.validators";
import InputError from "../permit/InputError.vue";

export default defineComponent({
  name: "UserSettings",
  props: {
    submitHandler: null, // SubmissionHandler<Record<string, any>>
    loading: {
      type: Boolean,
      default: false,
    },
    errorTxt: {
      type: String,
      default: "",
    },
    success: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    IonButton,
    IonCol,
    IonGrid,
    IonItem,
    IonInput,
    IonLabel,
    IonList,
    IonRow,
    InputError
  },
  setup(props: any) {
    const loaded = ref(false);
    const showErrors = ref(false);
    const { handleSubmit } = useForm();
    const onSubmit = handleSubmit(props.submitHandler);

    const { value: password, errorMessage: passError } = useField(
      "password",
      sixCharacters
    );
    const { value: vpassword, errorMessage: vpassError } = useField(
      "vpassword",
      sixCharacters
    );

    return {
      loaded,
      password,
      vpassword,
      passError,
      vpassError,
      onSubmit,
      showErrors,
      saveOutline,
      lockOpenOutline,
      lockClosedOutline,
    };
  },
  computed: {
    getUser(): any {
      return this.$store.state.admin.myUser;
    },
  },
  mounted() {
    // we need to wait for the store to get crap outta storage.
    setTimeout(() => {
      this.loaded = true;
    }, 300);
  },
  watch: {
    success(val) {
      if (val) {
        this.password = '';
        this.vpassword = '';
        this.showErrors = false;
      }
    }
  }
});
</script>
